/* eslint-disable class-methods-use-this -- need to support method complexities for legancy code */
/* eslint-disable max-lines -- need this file for handling all cases */
import {AuthenticatedUser, eventBus, feature, log, modelCache} from '@admin-tribe/acsc';
import {showError, showSuccess, showWarning, translateString} from '@admin-tribe/acsc-ui';

import rootStore from 'core/RootStore';
import {TEMPLATE_TYPES} from 'features/packages/components/create-package-modal/CreatePackageConstants';

import {isMacTypePlatform} from '../adminToolsUtils';
import {
  getCustomizerProgress,
  getDownloadUrl,
  getDownloaderUrl,
  retryBuildingPackage,
} from '../api/tronData';
import {
  ADMIN_PACKAGES,
  ADMIN_PACKAGES_PLUGINS,
  ALL_PACKAGES_CONSTANTS,
  CREATE_PACKAGE,
  CREATE_PACKAGE_CONSTANTS,
  CUSTOMIZER_PROGRESS,
  PACKAGE_TYPE_CONSTANTS,
  PRODUCT_SAPCODES,
  PROGRESS,
} from '../packagesConstants';
import PackagesAdobeProductsService from '../services/PackagesAdobeProductsService';
import PackagesEntitlementsService from '../services/PackagesEntitlementsService';
import PackagesExtensionsService from '../services/PackagesExtensionsService';
import PackagesLanServersService from '../services/PackagesLanServersService';

import {getRemainingDays, stringVersionCompare} from './packagesAdobeProductEntityUtils';

class PackagesAdminPackageEntity {
  /**
   * @description Method to auto download a package
   *
   * @param {Object} adminPackage corresponding AdminPackage
   * @param {Object} customizerProgress newly fetched customizerProgress for this AdminPackage
   */
  autoDownloadPackage = async (adminPackage, customizerProgress) => {
    await this.downloadPackage();
    if (this.isDownloaderCompatibleOS() && isMacTypePlatform(adminPackage.platform)) {
      showSuccess(
        translateString(
          {id: 'packages.alerts.allPackages.packageBuiltDownloader'},
          {
            daysRemaining: getRemainingDays(customizerProgress),
          }
        )
      );
    } else {
      showSuccess(
        translateString(
          {id: 'packages.alerts.allPackages.packageBuilt'},
          {
            daysRemaining: getRemainingDays(customizerProgress),
            packageName: adminPackage.name,
            platform: translateString({
              id: `packages.allPackages.platform.${adminPackage.platform}`,
            }),
          }
        )
      );
    }
  };

  /**
   * @description Method to fill status and download state for package
   *
   * @param {Object} adminPackage instance of AdminPackage
   */
  // eslint-disable-next-line complexity -- max complexity due to business need
  fillPackageState = (adminPackage) => {
    if (!adminPackage.customizerProgress) {
      return;
    }

    switch (adminPackage.customizerProgress.message) {
      case PROGRESS.CONSTANTS.UPLOAD_COMPLETE: {
        if (adminPackage.isCorruptedPackage) {
          adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_CORRUPTED;
          adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_CORRUPTED;
        } else {
          if (!adminPackage.pluginsInfo?.isResolved) {
            adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_UNRESOLVED;
          } else if (adminPackage.isUpToDate) {
            adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_UP_TO_DATE;
          } else {
            adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_NOT_UP_TO_DATE;
          }

          if (
            adminPackage.customizerProgress.onCallExpireTimeRemaining &&
            adminPackage.customizerProgress.onCallExpireTimeRemaining > 0
          ) {
            adminPackage.downloadDaysRemaining = getRemainingDays(adminPackage.customizerProgress);
            adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_DOWNLOAD;
          } else {
            adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_EXPIRED;
          }
        }
        break;
      }
      case PROGRESS.CONSTANTS.ERROR: {
        adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_ERROR;
        if (adminPackage.customizerProgress.retry) {
          adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_RETRY;
        } else {
          adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_NONE;
        }
        break;
      }
      case PROGRESS.CONSTANTS.WAITING: {
        adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_PREPARING;
        adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_NONE;
        break;
      }
      case PROGRESS.CONSTANTS.PROCESSING_START:
      case PROGRESS.CONSTANTS.CUSTOMIZATION_COMPLETE:
      case PROGRESS.CONSTANTS.DOWNLOAD_GENERIC_COMPLETE: {
        adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_BUILDING;
        adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_NONE;
        break;
      }
      case PROGRESS.CONSTANTS.CANCELLED: {
        adminPackage.statusState = ALL_PACKAGES_CONSTANTS.STATE_CANCELLING;
        adminPackage.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_NONE;
        break;
      }
      default: {
        break;
      }
    }
  };

  /**
   * @description Method to get list of all packaged applications from packageInfoWrapper
   *              (including STI base versions and invisible apps too)
   *
   * @param {Object} packageInfoWrapper packageInfoWrapper object
   * @returns {Array} Array of AdobeProducts
   */
  getAllPackagedAdobeProducts = (packageInfoWrapper) => {
    const packagedApps = [];

    this.getPackagedApps(packageInfoWrapper.adminPackage?.localizedProductIDs, packagedApps);
    this.getPackagedApps(packageInfoWrapper.adminPackage?.hyperdriveUpdateIDs, packagedApps);

    return packagedApps;
  };

  getMissingPluginData = (plugins) =>
    plugins.map((plugin) => {
      plugin.packagedVersion = this.pluginsInfo.pluginVersionIdList.find(
        (item) => item.id === plugin.id
      ).version;
      plugin.isUpToDate = stringVersionCompare(plugin.packagedVersion, plugin.version);
      return plugin;
    });

  /**
   * @description Method to get list of packaged applications from pacakgeInfoWrapper
   *
   * @param {Object} packageInfoWrapper packageInfoWrapper object
   * @returns {Array} Array of AdobeProducts
   */
  getPackagedAdobeProducts = (packageInfoWrapper) => {
    let packagedApps = [];

    if (packageInfoWrapper.adminPackage?.localizedProductIDs) {
      packageInfoWrapper.adminPackage.localizedProductIDs.forEach((localizedProductId) => {
        const product = PackagesAdobeProductsService.getAdobeProductBySapCodeVersionPlatform(
          this.getSapCodeVersionPlatformIDFromLocalizedID(localizedProductId)
        );
        // include STIs only if it's an update, or its update is available
        if (product && (!product.isSTI || (product.isSTI && !product.isUpToDate))) {
          packagedApps.push(product);
        }
      });
    }

    // filter applications that are not visible, and sort them by name then isSTI flag

    packagedApps = packagedApps
      .filter((packagedApp) => packagedApp.visible)
      .sort(this.sortBy('name'))
      .sort(this.sortBy('isSTI'));

    // move Creative Cloud app on top of this list if present
    const creativeCloudApp = packagedApps.filter((app) => app.sapCode === 'KCCC');

    packagedApps = packagedApps.filter((app) => app.sapCode !== 'KCCC');

    if (creativeCloudApp.length > 0) {
      packagedApps.unshift(creativeCloudApp[0]);
    }

    return packagedApps;
  };

  getPackagedApps = (list, packagedApps) => {
    list?.forEach((productId) => {
      const product = PackagesAdobeProductsService.getAdobeProductBySapCodeVersionPlatform(
        this.getSapCodeVersionPlatformIDFromLocalizedID(productId)
      );
      if (product) {
        packagedApps.push(product);
      }
      if (!this.isCorruptedPackage && product && product.isRevokedBuild) {
        this.isCorruptedPackage = true;
      }
    });
  };

  /**
   * @description Method to get list of packaged updates from pacakgeInfoWrapper
   *
   * @param {Object} packageInfoWrapper packageInfoWrapper object
   * @returns {Array} Array of AdobeProducts
   */
  getPackagedUpdates = (packageInfoWrapper) => {
    let packagedApps = [];
    // if showing CCP created packages, filter non-hdUpdate STIs
    if (
      feature.isEnabled('packages-ccp-created') &&
      feature.isEnabled('packages-ccp-created-allowed')
    ) {
      this.getPackagedApps(packageInfoWrapper.adminPackage.hyperdriveUpdateIDs, packagedApps);

      if (packageInfoWrapper.adminPackage.adobeUpdateIDs) {
        packageInfoWrapper.adminPackage.adobeUpdateIDs.forEach((refId) => {
          const update = rootStore.packagesAdobeUpdatesStore.getAdobeUpdateByRefId(refId);
          if (update) {
            packagedApps.push(update);
          }
        });
      }
    }

    // filter applications that are not visible, and sort them by name then isSTI flag
    packagedApps = packagedApps
      .filter((packagedApp) => packagedApp.visible)
      .sort(this.sortBy('name'))
      .sort(this.sortBy('isSTI'));

    return packagedApps;
  };

  /**
   * @description Method to get 'sapCode/version' from localizedProductID
   *
   * @param {String} localizedProductID localized Product ID
   * @returns {String} 'sapCode/version' ID
   */
  getSapCodeVersionPlatformIDFromLocalizedID = (localizedProductID) => {
    let endIndex = 0;
    let count = 0;
    while (endIndex < localizedProductID.length) {
      if (localizedProductID[endIndex] === '/') {
        count += 1;
        if (count === 3) {
          break;
        }
      }
      endIndex += 1;
    }
    return localizedProductID.slice(0, Math.max(0, endIndex));
  };

  /**
   * @description Method to get plugin info object for given set of packaged plugins
   *
   * @param {Array} pluginList List of packaged plugin info
   * @returns {Object} Plugin info object
   */
  initPluginsInformation = (pluginList) => ({
    hasError: false,
    isFetchInProgress: false,
    isResolved: pluginList.length === 0,
    numOfMissingPlugins: 0,
    plugins: [],
    pluginVersionIdList: pluginList.map((plugin) => ({
      id: plugin.extensionId,
      version: plugin.versionString,
    })),
  });

  /**
   * @description Method to check if AAL in the package is up to date from the given list of apps
   *
   * @param {JSON} aalInformation packageInfoWrapper object
   * @param {String} platform packageInfoWrapper object
   * @param {String} bits packageInfoWrapper object
   * @returns {Boolean} true if up to date
   */
  isAALUpToDate = (aalInformation, platform, bits) => {
    if (aalInformation) {
      return stringVersionCompare(
        aalInformation.version,
        rootStore.packagesUiConstantsStore.getLatestAALInformation(platform, bits).version
      );
    }

    return true;
  };

  isDownloaderCompatibleOS = () => {
    try {
      // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- neculaes@ to update
      const splitUserAgent = window.navigator.userAgent.split('Mac OS X ');
      if (splitUserAgent.length > 1) {
        let endOfVersion = splitUserAgent[1].indexOf(';');

        const firstParantheses = splitUserAgent[1].indexOf(')');

        if (firstParantheses > -1 && (firstParantheses < endOfVersion || endOfVersion === -1)) {
          endOfVersion = firstParantheses;
        }
        const unformattedVersion = splitUserAgent[1].slice(0, Math.max(0, endOfVersion));
        const versionArray = unformattedVersion
          .split('_')
          .join('.')
          .split('.')
          .map((value) => Number.parseInt(value, 10));

        return versionArray[0] >= 11 || (versionArray[0] === 10 && versionArray[1] >= 12);
      }
    } catch (error) {
      log.error(
        'PackagesAdminPackageEntity.isDownloaderCompatibleOS(): Unable to parse OS version info: ',
        error
      );
    }

    return false;
  };

  /**
   * @description Method to check if package is up to date from the given list of apps
   *
   * @param {Array} packagedApps List of packaged applications
   * @returns {Boolean} true if up to date
   */
  isPackageUpToDate = (packagedApps) => {
    const outdatedApps = packagedApps.filter((packagedApp) => {
      // Added custom handling for APRO 24.0 -- TRON-9204
      // Showing up to date if package containing only APRO 24.0
      if (packagedApp.sapCode === PRODUCT_SAPCODES.APRO && packagedApp.version === '24.0') {
        return false;
      }
      return packagedApp.isUpToDate === false;
    });

    let areAllLatestVersionsAlreadyPresent = true;

    outdatedApps.forEach((outdatedApp) => {
      areAllLatestVersionsAlreadyPresent =
        areAllLatestVersionsAlreadyPresent &&
        !!packagedApps.find(
          (packagedApp) => packagedApp.product_key === outdatedApp.getLatestProductKey()
        );
    });

    return areAllLatestVersionsAlreadyPresent;
  };

  /**
   * @description Method to process Package Progress from customizerProgress
   *
   * @param {Object} adminPackage corresponding AdminPackage
   * @param {Object} customizerProgress newly fetched customizerProgress for this AdminPackage
   */
  processPackageProgress = (adminPackage, customizerProgress) => {
    // notify user if it has just completed.
    if (customizerProgress.message === CUSTOMIZER_PROGRESS.UPLOAD_COMPLETE) {
      // if the last progress was not of success and now we got success,
      // it means this is the first time we got success progress message,
      // so we should notify the user.
      if (
        adminPackage.customizerProgress &&
        adminPackage.customizerProgress.message !== CUSTOMIZER_PROGRESS.UPLOAD_COMPLETE
      ) {
        // download package if created by Logged In User
        if (adminPackage.createdByLoggedInUser) {
          // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- gaukuma@ to update
          // istanbul ignore next
          this.autoDownloadPackage(adminPackage, customizerProgress);
        } else {
          showSuccess(
            translateString(
              {id: 'packages.alerts.allPackages.packageBuiltNoAutoDownload'},
              {
                packageName: adminPackage.name,
                platform: translateString({
                  id: `packages.allPackages.platform.${adminPackage.platform}`,
                }),
              }
            )
          );
        }
        modelCache.put(ADMIN_PACKAGES, 'ADMIN_PACKAGES', null);
        modelCache.put(ADMIN_PACKAGES_PLUGINS, adminPackage.packageId, null);
      }
    } else if (
      customizerProgress.message === CUSTOMIZER_PROGRESS.ERROR &&
      customizerProgress.errorCode === CUSTOMIZER_PROGRESS.ERROR_CODE.SERVER_UPDATE_REQUIRED &&
      !!adminPackage.frlPackagingInfo
    ) {
      showError(
        translateString(
          {id: 'packages.errors.allPackages.serverUpdateRequired'},
          {
            name: adminPackage.frlPackagingInfo.server.name,
          }
        )
      );
    }
    if (
      customizerProgress.message === CUSTOMIZER_PROGRESS.ERROR ||
      customizerProgress.errorCode === CUSTOMIZER_PROGRESS.ERROR_CODE.SERVER_UPDATE_REQUIRED
    ) {
      modelCache.remove(ADMIN_PACKAGES_PLUGINS, adminPackage.packageId);
    }
    adminPackage.customizerProgress = customizerProgress;
    this.fillPackageState(adminPackage);

    eventBus.emit(CREATE_PACKAGE.EVENTS.PROGRESS_STATUS, {
      adminPackage,
    });
  };

  /**
   * @description Helper function to sort data
   *
   * @returns {Sortvalue} based on condition
   */
  sortBy = (key) => (a, b) => (a[key] > b[key] ? 1 : -1);

  //////////////////////////////////////////
  /** Constructor for Admin Package  **/
  /**
   * @class
   * @description Creates a new AdminPackage for use.
   *
   * @param {Object} packageInfoWrapper Initialization packageInfoWrapper Object
   * @param {Integer} packageInfoWrapper.adminPackage.bits bits of package i.e, 32/64
   * @param {Timestamp} packageInfoWrapper.adminPackage.created When the package is created
   * @param {String} packageInfoWrapper.adminPackage.language Package creation language
   * @param {String} packageInfoWrapper.adminPackage.name Package display name
   * @param {String} packageInfoWrapper.adminPackage.adobeUserID User ID of the user who created the package
   * @param {String} packageInfoWrapper.adminPackage.packageBundleID Package Bundle ID
   * @param {String} packageInfoWrapper.adminPackage.packageID Package Unique ID
   * @param {String} packageInfoWrapper.adminPackage.platform Platform of the package i.e, WIN or MAC
   * @param {Object} packageInfoWrapper.adminPackage.configurations Package specific configurations
   * @param {String} packageInfoWrapper.adminPackage.userName User Name of the user who created the package
   * @param {Boolean} packageInfoWrapper.adminPackage.customized Package customized flag
   * @param {Object} packageInfoWrapper.adminPackage.aalInformation Information about AAL namd & version
   * @param {Boolean} packageInfoWrapper.adminPackage.ccdVersionInfoAvailable ccdVersionInfoAvailable flag
   * @param {Array} packageInfoWrapper.adminPackage.plugins Array of Package included plugins
   * @param {String} packageInfoWrapper.adminPackage.npdId Package NPDID
   * @param {String} packageInfoWrapper.adminPackage.packageType Infomation about package type i.e, "frlHighprivacyOnline", "managedPackage","selfService"
   * @param {Object} packageInfoWrapper.adminPackage.adminPackageFrl Package FRL information
   * @param {Object} packageInfoWrapper.customizerProgress Information about Package customization
   * @param {boolean} packageInfoWrapper.adminPackage.flatPackageEnabled indicates whether package is Flat type or not
   */
  // eslint-disable-next-line complexity, max-statements -- max complexity
  constructor(authuserid, packageInfoWrapper = {}) {
    this.bits = packageInfoWrapper.adminPackage?.bits;

    this.createdOn = packageInfoWrapper.adminPackage?.created;

    this.language = packageInfoWrapper.adminPackage?.language;

    this.name = packageInfoWrapper.adminPackage?.name;

    // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- neculaes@ to update
    this.adobeUserId = window.atob(packageInfoWrapper.adminPackage?.adobeUserID);
    this.packageBundleId = packageInfoWrapper.adminPackage?.packageBundleID;
    this.packageId = packageInfoWrapper.adminPackage?.packageID;
    this.platform = packageInfoWrapper.adminPackage?.platform;
    this.configurations = packageInfoWrapper.adminPackage?.configurations;
    this.userName = packageInfoWrapper.adminPackage?.userName;
    this.isCcpCreated = !packageInfoWrapper.adminPackage?.customized;
    this.aalInformation = packageInfoWrapper.adminPackage?.aalInformation;
    this.flatPackageEnabled = !!packageInfoWrapper.adminPackage?.flatPackageEnabled;
    this.productAddOnMap = packageInfoWrapper.adminPackage?.productAddOnMap;
    this.ccdVersionInfoAvailable = packageInfoWrapper.adminPackage?.ccdVersionInfoAvailable;
    this.pluginsInfo = this.initPluginsInformation(packageInfoWrapper.adminPackage?.plugins ?? []);

    this.npdId = packageInfoWrapper.adminPackage?.npdId;
    this.isDeviceInformationShared = this.setDeviceInformationShared(packageInfoWrapper);
    // Set package type. Could be null (assume managed) for old admin-console packages
    this.packageType = packageInfoWrapper.adminPackage?.packageType;
    this.isFrlOrNuellPackage =
      this.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE ||
      this.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED ||
      this.packageType === PACKAGE_TYPE_CONSTANTS.FRL_LAN ||
      this.packageType === PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE ||
      this.packageType === PACKAGE_TYPE_CONSTANTS.NUELL;

    if (this.isFrlOrNuellPackage) {
      this.frlPackagingInfo = packageInfoWrapper.adminPackage?.adminPackageFrl;
    }

    if (
      PackagesEntitlementsService.hasFrlLanEntitlement &&
      this.packageType === PACKAGE_TYPE_CONSTANTS.FRL_LAN &&
      !!this.frlPackagingInfo
    ) {
      this.frlPackagingInfo.server = PackagesLanServersService.getAllLanServers().find(
        (server) => server.serverId === this.frlPackagingInfo.serverId
      );
    }

    // set flag to check if this package was created by logged in user or not
    this.createdByLoggedInUser = this.setIsCreatedByLoggedInUser(this.adobeUserId);

    // add all packaged products
    this.packagedUpdates = this.getPackagedUpdates(packageInfoWrapper);
    this.packagedProducts = this.getPackagedAdobeProducts(packageInfoWrapper);
    this.packagedAppsCount = this.packagedProducts.length;

    // set isAALUpToDate flag
    this.isAALUpToDate = this.isAALUpToDate(this.aalInformation, this.platform, this.bits);

    // set isUpToDate flag
    this.isUpToDate = this.isPackageUpToDate(this.packagedProducts) && this.isAALUpToDate;

    // includes everything packaged including STI base versions as opposed to this.packagedProducts
    this.allPackagedProducts = this.getAllPackagedAdobeProducts(packageInfoWrapper);

    // set status and download state and add to polling queue if needed
    if (packageInfoWrapper.customizerProgress) {
      // save customizerProgress as it is in object itself
      this.customizerProgress = packageInfoWrapper.customizerProgress;
      this.fillPackageState(this);
    } else if (this.isCorruptedPackage) {
      this.statusState = ALL_PACKAGES_CONSTANTS.STATE_CORRUPTED;
      this.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_CORRUPTED;
      // eslint-disable-next-line no-negated-condition -- need to check negative condition
    } else if (!this.pluginsInfo.isResolved) {
      this.statusState = ALL_PACKAGES_CONSTANTS.STATE_UNRESOLVED;
      this.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_EXPIRED;
    } else {
      this.statusState = this.isUpToDate
        ? ALL_PACKAGES_CONSTANTS.STATE_UP_TO_DATE
        : ALL_PACKAGES_CONSTANTS.STATE_NOT_UP_TO_DATE;
      this.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_EXPIRED;
    }

    if (packageInfoWrapper.adminPackage?.templateType) {
      this.templateType = TEMPLATE_TYPES[packageInfoWrapper.adminPackage?.templateType];
    }
  }

  /**
   * @description Method to fetch download URL and initiate download for package
   *
   * @returns {Promise} returns promise
   */
  async downloadPackage() {
    let response = '';
    try {
      if (
        this.isDownloaderCompatibleOS() &&
        (this.platform === CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM ||
          this.platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM ||
          this.platform === CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM)
      ) {
        response = await getDownloaderUrl(this.packageId, {platform: 'mac'});
      } else {
        response = await getDownloadUrl(this.packageId);
      }
      const url = response.data;
      if (url !== null) {
        // eslint-disable-next-line @admin-tribe/admin-tribe/check-browser-globals -- neculaes@ to update
        window.open(url, '_self');
      }
      return Promise.resolve();
    } catch (error) {
      showError(
        translateString(
          {id: 'packages.errors.allPackages.errorDownloadingPackage'},
          {
            packageName: this.name,
          }
        )
      );
      log.error(
        'PackagesAdminPackageEntity.downloadPackage(): Unable to retry building on back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to fetch packages' progress from back-end, and update package
   *
   * @returns {Promise} If the operation was successfull
   */
  async fetchAndProcessPackageProgress() {
    try {
      const response = await getCustomizerProgress(this.packageID);
      this.processPackageProgress(this, response.data);
      return response;
    } catch (error) {
      log.error(
        'PackagesAdminPackageEntity.fetchAndProcessPackageProgress(): Unable to retrieve from back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
  }

  /**
   * @description Method to fetch and set plugin metadata from backend
   *
   * @returns {Promise} returns promise
   */
  async fetchPluginsData(forcedFetch = false) {
    const cachedAdminPackagePlugin = await modelCache.get(ADMIN_PACKAGES_PLUGINS, this.packageId);
    if (cachedAdminPackagePlugin && !forcedFetch) {
      this.arePluginsUpToDate = cachedAdminPackagePlugin.arePluginsUpToDate;
      this.isUpToDate = cachedAdminPackagePlugin.isUpToDate;
      this.pluginsInfo = cachedAdminPackagePlugin.pluginsInfo;
      this.updatePackageStatusState();
    } else if (this.pluginsInfo.isFetchInProgress || this.pluginsInfo.isResolved) {
      return Promise.resolve([]);
    } else {
      try {
        this.pluginsInfo.isFetchInProgress = true;
        const pluginsMap = {};
        await PackagesExtensionsService.fetchAndGetExtensions(
          this.packagedProducts.filter((product) => product.sapCode !== 'KCCC'),
          this.pluginsInfo.pluginVersionIdList.map((plugin) => ({id: plugin.id})),
          '',
          false,
          this.platform,
          this.bits
        )
          .then((plugins) => {
            this.pluginsInfo.plugins = plugins.map((plugin) => {
              plugin.packagedVersion = this.pluginsInfo.pluginVersionIdList.find(
                (item) => item.id === plugin.id
              ).version;
              plugin.isUpToDate = stringVersionCompare(plugin.packagedVersion, plugin.version);
              pluginsMap[plugin.id] = plugin;
              return plugin;
            });

            // if there are missing plugins, try to fetch them without compatibility checks
            if (this.pluginsInfo.pluginVersionIdList.length - this.pluginsInfo.plugins.length > 0) {
              // map the IDs for missing plugins

              const missingPluginIds = this.pluginsInfo.pluginVersionIdList
                .filter((item) => !pluginsMap[item.id])
                .map((plugin) => ({id: plugin.id}));

              // try to fetch these missing plugins without app compatibility checks
              return PackagesExtensionsService.fetchAndGetExtensions(
                [],
                missingPluginIds,
                '',
                false,
                this.platform,
                this.bits
              );
            }
            return [];
          })
          .then((plugins) => {
            const missingPluginData = this.getMissingPluginData(plugins);

            // eslint-disable-next-line unicorn/prefer-spread -- need to be looked later
            this.pluginsInfo.plugins = this.pluginsInfo.plugins.concat(
              missingPluginData.filter((item) => !this.pluginsInfo.plugins.includes(item))
            );

            this.pluginsInfo.numOfMissingPlugins =
              this.pluginsInfo.pluginVersionIdList.length - this.pluginsInfo.plugins.length;

            // do package level update status calculations
            this.arePluginsUpToDate = !this.pluginsInfo.plugins.some(
              (plugin) => plugin.isUpToDate === false
            );
            this.isUpToDate = this.isUpToDate && this.arePluginsUpToDate;
          });
      } catch (error) {
        this.pluginsInfo.hasError = true;
        log.error(
          `PackagesAdminPackageEntity.fetchPluginsData(): Failed to get plugins for package ${this.packageId} with error: `,
          error
        );
      } finally {
        this.pluginsInfo.isResolved = true;
        this.pluginsInfo.isFetchInProgress = false;
        this.updatePackageStatusState();
        modelCache.put(ADMIN_PACKAGES_PLUGINS, this.packageId, {
          arePluginsUpToDate: this.arePluginsUpToDate,
          isUpToDate: this.isUpToDate,
          pluginsInfo: this.pluginsInfo,
        });
      }
    }

    return Promise.resolve();
  }

  /**
   * @description Method to retry building failed package
   *
   * @returns {Promise} returns promise
   */
  async retryBuildingPackage() {
    try {
      const response = await retryBuildingPackage(this.packageId);
      if (response.data === 'SUCCESS') {
        // handle this case in controller since we'll be refreshing admin packages
      } else if (response.data === CUSTOMIZER_PROGRESS.ERROR_CODE.ERROR_ORG_COUNTER_REACHED) {
        showWarning(
          translateString(
            {id: 'packages.errors.allPackages.errorOrgLimit'},
            {
              orgLimitCounter: rootStore.packagesUiConstantsStore.maxSimultaneousPackageBuildCount,
            }
          )
        );
      } else if (
        response.data === CUSTOMIZER_PROGRESS.ERROR_CODE.ERROR_INVALID_CUSTOMIZATION_REQUEST ||
        response.data === CUSTOMIZER_PROGRESS.ERROR_CODE.ERROR_PACKAGE_EXPIRED
      ) {
        this.statusState = ALL_PACKAGES_CONSTANTS.STATE_ERROR;
        this.downloadState = ALL_PACKAGES_CONSTANTS.ACTION_NONE;
        showError(translateString({id: 'packages.errors.allPackages.errorBuilding'}));
      } else {
        showError(translateString({id: 'packages.errors.allPackages.errorBuilding'}));
      }
      return Promise.resolve();
    } catch (error) {
      log.error(
        'PackagesAdminPackageEntity.retryBuildingPackage(): Unable to retry building on back-end. Error: ',
        error
      );
      return Promise.reject(error);
    }
  }

  // set flag to check if this package shared device informtion or not
  setDeviceInformationShared(packageInfoWrapper) {
    return packageInfoWrapper.adminPackage?.deviceInformationShared;
  }

  // set flag to check if this package was created by logged in user or not
  setIsCreatedByLoggedInUser(adobeUserId) {
    const userDetails = AuthenticatedUser.get();
    const loggedInUserID = userDetails?.profile?.authId || userDetails?.profile?.userId;
    return adobeUserId === loggedInUserID;
  }

  /**
   * @description Method to update package status
   */
  updatePackageStatusState() {
    if (this.statusState === ALL_PACKAGES_CONSTANTS.STATE_UNRESOLVED) {
      this.statusState = this.isUpToDate
        ? ALL_PACKAGES_CONSTANTS.STATE_UP_TO_DATE
        : ALL_PACKAGES_CONSTANTS.STATE_NOT_UP_TO_DATE;
    }
  }
}

export default PackagesAdminPackageEntity;
/* eslint-enable max-lines -- need this file for handling all cases */
/* eslint-enable class-methods-use-this -- need to support legacy code */
