// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {INTERACTION} from '@admin-tribe/acsc';
import {ActionButton} from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';
import PropTypes from 'prop-types';
import React from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

const SophiaPromoCloseButton = ({onDismiss}) => {
  const {analytics} = useJsonPayload();

  if (!onDismiss) {
    return null;
  }

  const dismissHandler = () => {
    onDismiss();
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.DISMISS,
    });
  };

  return (
    <ActionButton
      data-testid="one-console-promo-close-action-button"
      isQuiet
      onPress={dismissHandler}
      position="absolute"
      right="size-0"
      top="size-65"
      variant="primary"
    >
      <Close data-testid="one-console-promo-close-action-button-icon" size="S" />
    </ActionButton>
  );
};

SophiaPromoCloseButton.propTypes = {
  onDismiss: PropTypes.func,
};

export default SophiaPromoCloseButton;
