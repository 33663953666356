import binkyUI from '@admin-tribe/acsc-ui';

const {getStrings, putStrings, translateString} = binkyUI.core.services.locales;

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- boag@ to update
async function loadStrings(language) {
  let localesMap;
  const strings = await import(
    /* webpackChunkName: "locale-lang" */ `../../../../.tmp/locales/${language}.json`
  );

  localesMap = putStrings(language, strings);

  if (language !== 'en') {
    // Always load the en strings so bulk operation csv header delocalization can use en strings
    const enStrings = await import(
      /* webpackChunkName: "locale-en-lang" */ `../../../../.tmp/locales/en.bb3f9cde44.json`
    );
    localesMap = putStrings('en', enStrings);
  }

  return localesMap;
}

export {getStrings, loadStrings, translateString};
