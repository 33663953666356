import {
  CONTRACT_LIST_CACHE_ID,
  CONTRACT_STATUS,
  ContactList,
  ContractList,
  GLOBAL_ADMIN_POLICY_LIST_CACHE_ID,
  GlobalAdminPolicyList,
  MIGRATION_LIST_CACHE_ID,
  MIGRATION_TYPE,
  MigrationList,
  ORGANIZATION_MARKET_SEGMENT,
  PRODUCT_LIST_CACHE_ID,
  PRODUCT_LIST_PI_CODE,
  ProductList,
  Store,
  feature,
  modelCache,
} from '@admin-tribe/acsc';
import cloneDeep from 'lodash/cloneDeep';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import auth from 'core/services/auth';
import OrgConsumables from 'core/services/product/org-consumables/OrgConsumables';
import GlobalBannerStore from 'features/global-banner/GlobalBannerStore';
import NotificationList from 'features/notifications/models/list/NotificationList';

class OrganizationStore extends Store {
  activeOrg = null;
  contactList = null;
  contractList = null;
  contractListIncludingInactive = null;
  globalAdminPolicyList = null;
  globalBannerStore = null;
  migrationList = null;
  notificationList = null;
  orgConsumables = null;
  productList = null;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      activeOrg: observable,
      activeOrgId: computed,
      contactList: observable,
      contractList: observable,
      contractListIncludingInactive: observable,
      globalAdminPolicyList: observable,
      globalBannerStore: observable,
      isActiveOrgDeveloper: computed,
      isActiveOrgEdu: computed,
      migrationList: observable,
      notificationList: observable,
      orgConsumables: observable,
      productList: observable,
      setActiveOrg: action,
      updateContactListSingleton: action,
      updateContractListSingleton: action,
      updateGlobalAdminPolicyListSingleton: action,
    });

    this.globalBannerStore = new GlobalBannerStore();
  }

  get activeOrgId() {
    return this.activeOrg.id;
  }

  async fetchData() {
    await auth.setActiveOrganization(this.activeOrg.id);
    const promises = [
      this.updateContractListSingleton(),
      this.updateMigrationListSingleton(),
      this.updateNotificationList(),
      this.updateProductListSingleton(),
      this.updateGlobalAdminPolicyListSingleton(),
      feature.isEnabled('temp_fg_orgid_parameter')
        ? // These features are refreshed after the application has been
          // bootstrapped, so you should not rely on feature flags from this call
          // if you need them during app startup.
          feature.refresh({floodgateQueryParams: {comOrgId: this.activeOrg.id}})
        : Promise.resolve(),
    ];
    if (auth.isUserOrgAdmin()) {
      promises.push(this.updateContactListSingleton());
    }

    return Promise.all(promises);
  }

  fetchSuccess() {
    super.fetchSuccess();

    if (feature.isDisabled('temp_refresh_fg_with_additional_context')) return;

    const context = this.getOrganizationContext();
    feature.refresh({floodgateQueryParams: context});
  }

  getOrganizationContext() {
    const activeContractList = this.contractList.items.filter((contract) =>
      contract.isStatusActive()
    );

    const contractContext =
      activeContractList.length === 1
        ? {
            comContractBuyingProgram: activeContractList[0].buyingProgram,
            comMarketSegment: activeContractList[0].marketSegment,
            contractBillingFrequency: activeContractList[0].getBillingFrequency(),
            contractCustomerSegment: activeContractList[0].customerSegment,
            contractSalesChannel: activeContractList[0].salesChannel,
          }
        : {
            comContractBuyingProgram: 'multipleContracts',
            ...(activeContractList.length > 1
              ? {
                  hasTeamDirectContracts: activeContractList.every((contract) =>
                    contract.isDirectContract()
                  ),
                }
              : {}),
          };

    const getProductContext = () => {
      // Gets the total assignable quantity of licenses
      const getLicenseCount = (licenses) =>
        licenses.reduce(
          (count, product) => count + product.licenseTupleList.getAssignableQuantity(),
          0
        );

      const activeLicenses = this.productList.items.filter((product) =>
        product.isLicenseStatusActive()
      );
      const assignableLicenseCount = getLicenseCount(activeLicenses);
      const trialLicenseCount = getLicenseCount(
        activeLicenses.filter((product) => product.licenseTupleList.hasTrialLicenses())
      );

      return {
        contractActiveLicenseCount: assignableLicenseCount,
        contractActivePaidLicenseCount: assignableLicenseCount - trialLicenseCount,
      };
    };

    return {
      comOrgId: this.activeOrg.id,
      contractCountry: this.activeOrg.countryCode,
      ...contractContext,
      ...getProductContext(),
    };
  }

  get isActiveOrgDeveloper() {
    return this.activeOrg.isDeveloperOrg();
  }

  get isActiveOrgEdu() {
    return (
      (this.activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.GOVERNMENT &&
        this.activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.COMMERCIAL) ||
      feature.isEnabled('force_edu_market_segment')
    );
  }

  async setActiveOrg(activeOrg) {
    this.activeOrg = activeOrg;
    this.globalBannerStore.clearAll();
    await this.load();
  }

  async updateContactListSingleton() {
    const newContactList = await ContactList.get({orgId: this.activeOrg.id});
    runInAction(() => {
      this.contactList = newContactList;
    });
    return newContactList;
  }

  async updateContractListSingleton({forceRefresh} = {}) {
    if (forceRefresh) {
      modelCache.clear(CONTRACT_LIST_CACHE_ID);
    }

    const newContractList = await ContractList.get({
      includeAutoRenewal: feature.isEnabled('auto_renewal_opt_in_self_service'),
      includeInactiveContracts: true,
      includeTermsAcceptances: feature.isEnabled('temp_terms_redirect'),
      isCacheable: true,
      orgId: this.activeOrg.id,
    });

    runInAction(() => {
      this.contractListIncludingInactive = newContractList;
      this.contractList = cloneDeep(newContractList);
      this.contractList.items = this.contractList.items.filter(
        (contract) => contract.status !== CONTRACT_STATUS.INACTIVE
      );
    });

    this.contractList.includeInactiveContracts = false;
    modelCache.put(CONTRACT_LIST_CACHE_ID, this.contractList.getKey(), this.contractList);
    modelCache.put(
      CONTRACT_LIST_CACHE_ID,
      this.contractListIncludingInactive.getKey(),
      this.contractListIncludingInactive
    );

    // TODO: Remove this once React migration is completed and switching isn't required
    // TODO: move updateContractListSingleton to promise post React migration
    const params = {};
    if (this.contractList && this.contractList.items.length === 1) {
      params.comContractBuyingProgram = this.contractList.items[0].buyingProgram;
      params.comMarketSegment = this.contractList.items[0].marketSegment;
      params.contractCustomerSegment = this.contractList.items[0].customerSegment;
      params.contractSalesChannel = this.contractList.items[0].salesChannel;
    } else {
      params.comContractBuyingProgram = 'multipleContracts';
    }

    if (feature.isEnabled('temp_fg_orgid_parameter')) {
      // These features are refreshed after the application has been
      // bootstrapped, so you should not rely on feature flags from this call
      // if you need them during app startup.
      params.comOrgId = this.activeOrg.id;
      feature.refresh({floodgateQueryParams: {...params}});
    } else {
      feature.refresh({floodgateQueryParams: {...params}});
    }

    return this.contractList;
  }

  async updateGlobalAdminPolicyListSingleton() {
    const newGlobalAdminPolicyList = await GlobalAdminPolicyList.get({
      isCacheable: true,
      orgId: this.activeOrg.id,
    });

    runInAction(() => {
      this.globalAdminPolicyList = newGlobalAdminPolicyList;
    });

    modelCache.put(
      GLOBAL_ADMIN_POLICY_LIST_CACHE_ID,
      newGlobalAdminPolicyList.getKey(),
      newGlobalAdminPolicyList
    );

    return newGlobalAdminPolicyList;
  }

  async updateMigrationListSingleton() {
    const types = [
      MIGRATION_TYPE.ESM_TYPE1,
      MIGRATION_TYPE.MA_LEGACY_TO_ADMIN_CONSOLE,
      MIGRATION_TYPE.VIP2DIRECT,
      MIGRATION_TYPE.VIPMP,
      MIGRATION_TYPE.T2E,
    ];

    const params = {
      isCacheable: true,
      orgId: this.activeOrg.id,
      types,
    };

    const newMigrationList = await MigrationList.get(params);
    runInAction(() => {
      this.migrationList = newMigrationList;
    });

    modelCache.put(
      MIGRATION_LIST_CACHE_ID,
      // MigrationList doesn't extend JilModelList, so we have to generate the key ourselves
      newMigrationList.getModelKey(params),
      newMigrationList
    );

    return newMigrationList;
  }

  updateNotificationList() {
    this.notificationList = NotificationList.get();
  }

  async updateProductListSingleton() {
    const newProductList = await ProductList.get({
      includeCancellationData:
        feature.isEnabled('temp_self_cancel') ||
        feature.isEnabled('temp_self_cancel_trial_with_payment'),
      includeInactive: false,
      includePricingData:
        feature.isEnabled('temp_self_cancel') ||
        feature.isEnabled('temp_self_cancel_trial_with_payment'),
      orgId: this.activeOrg.id,
      processingInstructionCodes: [
        PRODUCT_LIST_PI_CODE.GLOBAL_ADMINISTRATION,
        PRODUCT_LIST_PI_CODE.ADMINISTRATION,
      ],
    });

    if (feature.isEnabled('temp_b2b_free_provisioning')) {
      newProductList.freeItems = newProductList.items.filter((product) =>
        product.isDisplayLocationSettingsOnly()
      );

      newProductList.items = newProductList.items.filter(
        (product) => !product.isDisplayLocationSettingsOnly()
      );
    }

    runInAction(() => {
      this.productList = newProductList;
      this.orgConsumables = new OrgConsumables({
        orgId: this.activeOrg.id,
        productList: this.productList,
      });
    });

    modelCache.put(PRODUCT_LIST_CACHE_ID, newProductList.getKey(), newProductList);

    return newProductList;
  }
}

export default OrganizationStore;
