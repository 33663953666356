/* eslint-disable max-lines -- few lines over because of the ff check  */
import {eventBus, feature} from '@admin-tribe/acsc';
import {showError, showSuccess} from '@admin-tribe/acsc-ui';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {UPLOAD_METADATA_ERROR_CODES} from 'features/settings/api/ims-federated';
import {
  COUNTRY_CODE_PROPERTY_NAME_AZURE_OIDC,
  COUNTRY_CODE_PROPERTY_NAME_GENERIC_SAML,
  COUNTRY_CODE_PROPERTY_NAME_OIDC,
} from 'features/settings/common/constants/attributeMappings';
import IdpEntity, {IDP_TYPES} from 'features/settings/entities/IdpEntity';
import ExternalAuthService, {AZURE_SCOPES} from 'features/settings/services/ExternalAuthService';

const IDP_ALREADY_EXISTS_ERROR_CODE = 'idp_already_exists';

const METADATA_ERRORS = {
  [UPLOAD_METADATA_ERROR_CODES.CANNOT_READ_METADATA]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.cannotReadMetadata',
  [UPLOAD_METADATA_ERROR_CODES.EMPTY_METADATA]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.emptyMetadata',
  [UPLOAD_METADATA_ERROR_CODES.INVALID_METADATA]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.invalidMetadata',
  [UPLOAD_METADATA_ERROR_CODES.INVALID_XML]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.invalidXml',
  [UPLOAD_METADATA_ERROR_CODES.MALFORMED_SSO_URL]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.malformedSsoUrl',
  [UPLOAD_METADATA_ERROR_CODES.MULTIPLE_ENTITY_DESCRIPTORS]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.multipleEntityDescriptors',
  [UPLOAD_METADATA_ERROR_CODES.NO_IDP_SSO_DESCRIPTOR]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.noIdpSsoDescriptor',
  [UPLOAD_METADATA_ERROR_CODES.NO_SSO_SERVICE_PRESENT]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.noSsoServicePresent',
  [UPLOAD_METADATA_ERROR_CODES.UNRECOGNIZED_METADATA_FORMAT]:
    'settings.setupDirectoryAuthentication.toasts.uploadErrors.unrecognizedMetadataFormat',
};

const getAzureLoginInfo = () => {
  if (ExternalAuthService.isLoginInProgress()) {
    return ExternalAuthService.completeAuthentication();
  }

  return null;
};

/**
 * Hook that defines a set of callbacks used in `useDirectorySetup`.
 * This is not intended to be used as a standalone hook.
 */
const useCallbacks = ({directorySetupStore, setIsDoingExternalAuth, setIdpInfo, idpInfo}) => {
  const intl = useIntl();

  const restoreAzureIdpCreation = useCallback(() => {
    const isExternalLoginRefactorEnabled = feature.isEnabled('temp_external_login_refactor');

    // check for login
    const azureLoginInfo = getAzureLoginInfo();

    if (azureLoginInfo?.data?.isAddingAzureIdp) {
      // we're in the middle of an Azure login
      setIsDoingExternalAuth(true);

      const azureOrgId = isExternalLoginRefactorEnabled
        ? azureLoginInfo.queryData?.tenant
        : azureLoginInfo.queryData?.get?.('tenant');

      if (!azureOrgId) {
        showError(
          intl.formatMessage({
            id: 'settings.directories.createDirectoryModal.toasts.missingAzureOrgId',
          })
        );

        setIsDoingExternalAuth(false);

        return [false, azureLoginInfo.data];
      }

      // set a provisional IdP
      directorySetupStore.setIdp(
        new IdpEntity({
          aadOrganization: azureOrgId,
          federationType: IDP_TYPES.AZURE,
        })
      );

      // complete the idp info with the idp details
      setIdpInfo({
        aadOrganization: azureOrgId,
        type: IDP_TYPES.AZURE,
      });

      // finished azure login
      setIsDoingExternalAuth(false);

      return [true, azureLoginInfo.data];
    }

    if (azureLoginInfo?.data.isEditingAzureIdp) {
      // we're in the middle of an Azure login
      setIsDoingExternalAuth(true);

      const azureOrgId = isExternalLoginRefactorEnabled
        ? azureLoginInfo.queryData?.tenant
        : azureLoginInfo.queryData?.get('tenant');

      if (!azureOrgId) {
        showError(
          intl.formatMessage({
            id: 'settings.directories.createDirectoryModal.toasts.missingAzureOrgId',
          })
        );

        setIsDoingExternalAuth(false);

        return [false, azureLoginInfo];
      }

      // complete the idp info with the idp details
      setIdpInfo({
        aadOrganization: azureOrgId,
        type: IDP_TYPES.AZURE,
      });

      // finished azure login
      setIsDoingExternalAuth(false);

      return [true, azureLoginInfo];
    }

    return [false, null];
  }, [directorySetupStore, setIsDoingExternalAuth, setIdpInfo, intl]);

  const loginToAzure = useCallback(
    (data = {isAddingAzureIdp: true}) => {
      setIsDoingExternalAuth(true);

      ExternalAuthService.startAzureAdminConsentAuthentication(
        [AZURE_SCOPES.EMAIL, AZURE_SCOPES.OPENID, AZURE_SCOPES.PROFILE].join(' '),
        {
          directoryId: directorySetupStore.directoryStore.directoryId,
          directoryName: directorySetupStore.directoryStore.name,
          ...data,
        }
      );
    },
    [
      directorySetupStore.directoryStore.directoryId,
      directorySetupStore.directoryStore.name,
      setIsDoingExternalAuth,
    ]
  );

  const createIdp = useCallback(async () => {
    try {
      const {type, isGoogleSelected, ...idpData} = idpInfo;

      await directorySetupStore.createIdp({
        federationType: type,
        ...idpData,
      });

      const {idp} = directorySetupStore;

      if (idp.isSoidc) {
        const {directoryStore} = directorySetupStore;

        eventBus.emit('EDU IdP added', {
          addedIdpName: idp.providerName,
          directoryId: directoryStore.directoryId,
          idps: directoryStore.idps,
        });
      }

      showSuccess(
        intl.formatMessage({
          id: 'settings.directories.createDirectoryModal.toasts.createdIdp',
        })
      );

      return true;
    } catch (error) {
      const errorCode = error.response?.data?.error;

      if (errorCode === IDP_ALREADY_EXISTS_ERROR_CODE) {
        showError(
          intl.formatMessage({
            id: 'settings.directories.createDirectoryModal.toasts.idpAlreadyExists',
          })
        );

        return false;
      }

      showError(
        intl.formatMessage({
          id: 'settings.directories.createDirectoryModal.toasts.errorCreatingIdp',
        })
      );

      return false;
    }
  }, [directorySetupStore, intl, idpInfo]);

  const updateProvider = useCallback(
    (providerName) => {
      directorySetupStore.setIdp(
        new IdpEntity({
          federationType: IDP_TYPES.SOIDC,
          providerName,
        })
      );

      // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- not testing react set state functions
      // istanbul ignore next -- not testing this
      setIdpInfo((idpData) => ({
        ...idpData,
        providerName,
      }));
    },
    [directorySetupStore, setIdpInfo]
  );

  const updateIdp = useCallback(async () => {
    // Update context classes only if the values are changed
    if (
      directorySetupStore.data.mfaAuthnContextClasses !==
        directorySetupStore.idp.mfaAuthnContextClasses ||
      directorySetupStore.data.reauthAuthnContextClasses !==
        directorySetupStore.idp.reauthAuthnContextClasses
    ) {
      await directorySetupStore.updateIdp({
        data: {
          mfaAuthnContextClasses: directorySetupStore.data.mfaAuthnContextClasses,
          reauthAuthnContextClasses: directorySetupStore.data.reauthAuthnContextClasses,
        },
        idpId: directorySetupStore.idp.id,
      });

      showSuccess(
        intl.formatMessage({
          id: 'settings.setupDirectoryAuthentication.toasts.successfullyUpdatedAttributes',
        })
      );
    }
  }, [directorySetupStore, intl]);

  const updateAzureTenant = useCallback(async () => {
    try {
      await directorySetupStore.updateIdp({
        data: {
          aadOrganization: directorySetupStore.newTenantId,
        },
        idpId: directorySetupStore.idp.id,
      });

      showSuccess(
        intl.formatMessage({id: 'settings.setupDirectoryAuthentication.toasts.updatedTenantId'})
      );

      return true;
    } catch (error) {
      showError(
        intl.formatMessage({id: 'settings.setupDirectoryAuthentication.toasts.updateTenantIdError'})
      );

      return false;
    }
  }, [directorySetupStore, intl]);

  const uploadIdpMetadata = useCallback(async () => {
    // Upload new metadata only if one is selected
    // A size 0 file means we populated the field with
    // an empty file to show that one is already uploaded
    if (directorySetupStore.data.metadataFile.size > 0) {
      await directorySetupStore.directoryStore.uploadIdpMetadata({
        idpId: directorySetupStore.idp.id,
        metadataFile: directorySetupStore.data.metadataFile,
      });

      showSuccess(
        intl.formatMessage({
          id: 'settings.setupDirectoryAuthentication.toasts.successfullyUploadedMetadata',
        })
      );
    }
  }, [
    directorySetupStore.data.metadataFile,
    directorySetupStore.idp?.id,
    directorySetupStore.directoryStore,
    intl,
  ]);

  const saveIdp = useCallback(async () => {
    if (directorySetupStore.isEditingAzureIdp && directorySetupStore.idp.isAzure) {
      return updateAzureTenant();
    }

    if (
      (directorySetupStore.idp.isAzure && !directorySetupStore.idp.id) ||
      directorySetupStore.idp?.isSoidc
    ) {
      return createIdp();
    }

    if (directorySetupStore.idp.isSaml && directorySetupStore.hasIdpChanges) {
      try {
        await uploadIdpMetadata();
        await updateIdp();

        directorySetupStore.setIdpChanges(false);

        return true;
      } catch (error) {
        const response = error.response;
        const id =
          METADATA_ERRORS[response?.data?.error] ??
          'settings.setupDirectoryAuthentication.toasts.errorUpdatingIdp';

        showError(
          intl.formatMessage({
            id,
          })
        );

        return false;
      }
    }

    return true;
  }, [directorySetupStore, updateAzureTenant, createIdp, uploadIdpMetadata, updateIdp, intl]);

  const saveDefaultCountry = useCallback(async () => {
    let propertyName;

    // Azure OIDC uses a different attribute name for country
    if (directorySetupStore.idp.isAzure) {
      propertyName = COUNTRY_CODE_PROPERTY_NAME_AZURE_OIDC;
    } else if (directorySetupStore.idp.isSaml) {
      propertyName = COUNTRY_CODE_PROPERTY_NAME_GENERIC_SAML;
    } else if (directorySetupStore.idp.isSoidc) {
      propertyName = COUNTRY_CODE_PROPERTY_NAME_OIDC;
    } else {
      throw new Error('No property name matching the current IdP');
    }

    await directorySetupStore.updateDefaultCountry(
      propertyName,
      directorySetupStore.data.defaultCountry
    );
  }, [directorySetupStore]);

  const saveJitSettings = useCallback(async () => {
    try {
      // save the JIT settings on the IdP
      await directorySetupStore.directoryStore.updateIdp({
        data: {
          jitConfig: {
            accountCreation: directorySetupStore.data.jitStatus,
            syncStrategy: directorySetupStore.data.updateStrategy,
          },
        },
        idpId: directorySetupStore.idp.id,
      });

      // save/update the default country property if default country provided
      if (directorySetupStore.data.defaultCountry) {
        await saveDefaultCountry();
      }

      directorySetupStore.setJitChanges(false);
      showSuccess(
        intl.formatMessage({
          id: 'settings.setupDirectoryAuthentication.toasts.successfullyUpdatedJitSettings',
        })
      );

      return true;
    } catch (error) {
      showError(
        intl.formatMessage({
          id: 'settings.setupDirectoryAuthentication.toasts.errorSavingJitSettings',
        })
      );

      return false;
    }
  }, [directorySetupStore, intl, saveDefaultCountry]);

  return {
    createIdp,
    getAzureLoginInfo,
    loginToAzure,
    restoreAzureIdpCreation,
    saveIdp,
    saveJitSettings,
    updateAzureTenant,
    updateProvider,
  };
};

export default useCallbacks;
export {IDP_ALREADY_EXISTS_ERROR_CODE};
/* eslint-enable max-lines -- few lines over because of the ff check */
