import {DetailSection} from '@admin-tribe/acsc-ui';
import {Flex, Grid, Image, Text, View} from '@adobe/react-spectrum';
import {TooltipButton} from '@pandora/react-tooltip-button';
import Alert from '@spectrum-icons/workflow/Alert';
import LinkOut from '@spectrum-icons/workflow/LinkOut';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {openLink} from 'features/packages/adminToolsUtils';
import AddOnsSummary from 'features/packages/components/create-package-modal/screens/choose-apps/add-ons/add-ons-summary/AddOnsSummary';
import {PackageSummaryDrawerContext} from 'features/packages/components/package-summary-drawer/PackageSummaryDrawerContextProvider';
import {CREATE_PACKAGE_CONSTANTS, PRODUCT_SAPCODES} from 'features/packages/packagesConstants';
import PackagesAdobeProductsService from 'features/packages/services/PackagesAdobeProductsService';

import styles from './IncludedAppsList.pcss';
import AppDetails from './app-details/AppDetails';

const IncludedAppsList = ({showPlugins}) => {
  const intl = useIntl();
  const {attributes} = useContext(PackageSummaryDrawerContext);

  function getAALIconUrl() {
    return rootStore.packagesUiConstantsStore.iconBucketBaseUrl
      ? `${rootStore.packagesUiConstantsStore.iconBucketBaseUrl}/ASSETLINK/${attributes.aalInformation.version}/96x96.png`
      : '';
  }

  const getMissingPluginsString = () =>
    intl.formatMessage(
      {id: 'packages.summaryDrawerBody.includedPlugins.missingPluginsTooltip'},
      {numOfMissingPlugins: attributes.package.pluginsInfo.numOfMissingPlugins}
    );

  function showProductUpdateTooltip(product) {
    // Added custom handling for APRO 24.0 -- TRON-9204
    // Showing up to date if package containing only APRO 24.0
    if (product.sapCode === PRODUCT_SAPCODES.APRO && product.version === '24.0') {
      return false;
    }
    return !attributes.isTemplate && !product.isUpToDate && !attributes.showCcpCreatedPackages;
  }

  function showStiUpdateVersionInfo(product) {
    return (
      showProductUpdateTooltip(product) &&
      product.isSTI &&
      attributes.platformStr === CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM
    );
  }

  function showLoader() {
    return showPlugins && !attributes.package.pluginsInfo.isResolved;
  }

  function showProducts() {
    if (showPlugins) {
      return attributes.package.pluginsInfo.isResolved;
    }

    return true;
  }

  function getHeading() {
    if (showPlugins) {
      return intl.formatMessage({id: 'packages.createPackage.finalizePackagePage.includedPlugins'});
    }

    return intl.formatMessage({id: 'packages.summaryDrawerBody.includedApplications.title'});
  }

  function getProductsArray() {
    if (showPlugins) {
      return attributes.package.pluginsInfo.plugins;
    }

    return attributes.includedApplications;
  }

  function showInfoTooltip(product) {
    if (showPlugins) {
      return product.learnMoreUrl !== '';
    }

    return product.getLocaleInfoUrl === undefined ? false : product.getLocaleInfoUrl() !== '';
  }

  function showAlertTooltip(product) {
    if (showPlugins) {
      return !product.isUpToDate;
    }

    return showStiUpdateVersionInfo(product);
  }

  function getNewVersionTooltipText(product) {
    if (showPlugins) {
      return intl.formatMessage(
        {
          id: 'packages.summaryDrawerBody.includedPlugins.newerVersionAvailable',
        },
        {
          newPlugin: product.name,
          newVersion: product.version,
        }
      );
    }
    return intl.formatMessage({
      id: 'packages.summaryDrawerBody.includedApplications.newerVersionAvailableFirstTooltip',
    });
  }

  function showAddOnsSummary(product) {
    return (
      !attributes.isTemplate &&
      !showPlugins &&
      attributes.productAddOnMap &&
      attributes.productAddOnMap[product.product_key]?.length > 0
    );
  }

  function isErrorInPlugins() {
    return showPlugins && attributes.showPluginsErrorTooltip;
  }

  function getErrorTextInPlugins() {
    return intl.formatMessage({
      id: 'packages.summaryDrawerBody.includedPlugins.errorPluginsTooltip',
    });
  }

  function getNewVersionAvailableTooltipText(product) {
    const latestProduct = PackagesAdobeProductsService.getLatestAdobeProduct(product);
    return intl.formatMessage(
      {
        id: 'packages.summaryDrawerBody.includedApplications.newerVersionAvailableSecondTooltip',
      },
      {
        productName: latestProduct.name,
        productVersion: latestProduct.version,
      }
    );
  }

  function getRightHeaderContent() {
    return (
      showPlugins &&
      attributes.showMissingPluginsTooltip && (
        <TooltipButton
          hoverText={getMissingPluginsString()}
          isQuiet
          tooltipDelayMs="0"
          variant="action"
        >
          <Alert size="S" />
        </TooltipButton>
      )
    );
  }

  return (
    <View>
      <DetailSection
        errorText={getErrorTextInPlugins()}
        headingLevel={3}
        isLoading={showLoader()}
        rightHeaderContent={getRightHeaderContent()}
        showError={isErrorInPlugins()}
        title={getHeading()}
        variant="drawer"
      >
        <View marginStart="size-200" maxHeight="size-3600" overflow="auto">
          {showProducts() && (
            <Flex data-testid="product-list-container" direction="column">
              {/* AAL Plugin */}
              {showPlugins && attributes.aalInformation && (
                <Grid
                  areas={['plugin tooltips']}
                  columns={['4fr', '0.5fr']}
                  gap="size-350"
                  rows={['auto']}
                >
                  <Flex direction="row" gridArea="plugin" marginBottom="size-150">
                    <Image
                      alt=""
                      data-testid="aal-icon-image"
                      height="size-300"
                      marginEnd="size-150"
                      src={getAALIconUrl()}
                      width="size-300"
                    />
                    {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- plugin name styles */}
                    <Text data-testid="aal-info-name" UNSAFE_className={styles['plugin-name-text']}>
                      {attributes.aalInformation.name}
                      {/* eslint-disable-next-line @admin-tribe/admin-tribe/jsx-no-unsafe-attributes -- plugin info styles */}
                      <Text marginStart="size-100" UNSAFE_className={styles['plugin-info-text']}>
                        &#40;{attributes.aalInformation.version}&#41;
                      </Text>
                    </Text>
                  </Flex>
                  {!attributes.package.isAALUpToDate && (
                    <View gridArea="tooltips">
                      <TooltipButton
                        hoverText={intl.formatMessage({
                          id: 'packages.summaryDrawerBody.includedPlugins.newerVersionAvailable',
                          newPlugin: attributes.latestAALInformation.name,
                          newVersion: attributes.latestAALInformation.version,
                        })}
                        isQuiet
                        tooltipDelayMs="0"
                        variant="action"
                      >
                        <Alert size="S" />
                      </TooltipButton>
                    </View>
                  )}
                </Grid>
              )}
              {getProductsArray().map((product) => (
                <View key={showPlugins ? product.id : product.product_key}>
                  <Grid
                    areas={['product tooltip-info tooltip-alert']}
                    columns={['4fr', '0.5fr', '0.5fr']}
                    marginBottom="size-10"
                    marginTop="size-10"
                    rows={['auto']}
                  >
                    <Flex direction="row" gridArea="product" marginTop="size-50">
                      <AppDetails product={product} showPlugins={showPlugins} />
                    </Flex>
                    <View gridArea="tooltip-info">
                      {showInfoTooltip(product) && (
                        <TooltipButton
                          data-testid="more-info-tooltip"
                          hoverText={intl.formatMessage(
                            {
                              id: 'packages.summaryDrawerBody.includedApplications.moreInfoHrefLinkText',
                            },
                            {product: product.name}
                          )}
                          isQuiet
                          onPress={() =>
                            openLink(
                              showPlugins ? product.learnMoreUrl : product.getLocaleInfoUrl()
                            )
                          }
                          tooltipDelayMs="0"
                          variant="action"
                        >
                          <LinkOut size="S" />
                        </TooltipButton>
                      )}
                    </View>
                    <View gridArea="tooltip-alert">
                      {showAlertTooltip(product) && (
                        <TooltipButton
                          data-testid="new-version-available-tooltip-first"
                          hoverText={getNewVersionTooltipText(product)}
                          isQuiet
                          tooltipDelayMs="0"
                          variant="action"
                        >
                          <Alert size="S" />
                        </TooltipButton>
                      )}
                      {!showPlugins &&
                        showProductUpdateTooltip(product) &&
                        !showStiUpdateVersionInfo(product) && (
                          <TooltipButton
                            data-testid="new-version-available-tooltip-second"
                            hoverText={getNewVersionAvailableTooltipText(product)}
                            isQuiet
                            tooltipDelayMs="0"
                            variant="action"
                          >
                            <Alert size="S" />
                          </TooltipButton>
                        )}
                    </View>
                  </Grid>
                  {showAddOnsSummary(product) && (
                    <View marginStart="size-450">
                      <AddOnsSummary
                        selectedAddOnsList={attributes.productAddOnMap[product.product_key]}
                      />
                    </View>
                  )}
                </View>
              ))}
            </Flex>
          )}
        </View>
      </DetailSection>
    </View>
  );
};

IncludedAppsList.propTypes = {
  /**
   * Boolean to tell if we are showing plugins
   */
  showPlugins: PropTypes.bool,
};

export default IncludedAppsList;
