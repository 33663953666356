import {DetailSection} from '@admin-tribe/acsc-ui';
import {Grid, Text} from '@adobe/react-spectrum';
import {Device} from '@pandora/react-data-model-device';
import {isValid} from 'date-fns';
import React from 'react';
import {useIntl} from 'react-intl';

import {useDeviceDetailsContext} from '../context/DeviceDetailsContext';
import {
  getLatestStateChangeTimestamp,
  getTranslatedLastUpdate,
  getTranslatedStatusWithDate,
  getValue,
} from '../utils/deviceDrawerUtils';

const NAMESPACE = 'products.deviceLicenses.deviceDetails';

// Styling for labels.
const textStyle = {
  color: 'var(--color-grey-700)',
};

/**
 * A component to display relevant details of the a device
 */
const Details = () => {
  const {deviceDetails: deviceDetailsObject, showIPAddress} = useDeviceDetailsContext();
  const deviceDetails = new Device(deviceDetailsObject);
  const intl = useIntl();

  const detailsFields = [
    {
      key: intl.formatMessage({id: `${NAMESPACE}.details.keys.status`}),
      value: getTranslatedStatusWithDate({
        activationState: deviceDetails.activationState,
        eventDate: getLatestStateChangeTimestamp(deviceDetails),
        intl,
        namespace: NAMESPACE,
      }),
    },
    {
      key: intl.formatMessage({id: `${NAMESPACE}.details.keys.deviceId`}),
      value: deviceDetails.deviceId,
    },
    ...(showIPAddress
      ? [
          {
            key: intl.formatMessage({id: `${NAMESPACE}.details.keys.ipAddress`}),
            value: deviceDetails.ipAddress,
          },
        ]
      : []),
    {
      key: intl.formatMessage({id: `${NAMESPACE}.details.keys.osUserId`}),
      value: deviceDetails.osUserId,
    },
    {
      key: intl.formatMessage({id: `${NAMESPACE}.details.keys.isVDI`}),
      value: deviceDetails.enableVdiMarkerExists,
    },
    {
      key: intl.formatMessage({id: `${NAMESPACE}.details.keys.osName`}),
      value: deviceDetails.osName,
    },
    {
      key: intl.formatMessage({id: `${NAMESPACE}.details.keys.osVersion`}),
      value: deviceDetails.osVersion,
    },
  ];

  const lastDeviceUsageTimestamp = new Date(deviceDetails.lastDeviceUsageTimestamp);
  const validLastDeviceUsageTimestamp = isValid(lastDeviceUsageTimestamp)
    ? lastDeviceUsageTimestamp.getTime()
    : 0;

  const eventDateInMilliseconds = Math.max(
    new Date(getLatestStateChangeTimestamp(deviceDetails)).getTime(),
    validLastDeviceUsageTimestamp
  );

  return (
    <DetailSection
      data-testid="frl-device-details-drawer-details-section"
      // eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- needed to add data-testid
      rightHeaderContent={
        <Text
          data-testid="frl-device-details-last-update"
          marginBottom="size-100"
          marginTop="size-100"
          UNSAFE_style={{fontStyle: 'italic'}}
        >
          {getTranslatedLastUpdate({
            eventDateInMilliseconds,
            intl,
            namespace: NAMESPACE,
          })}
        </Text>
      }
      title={intl.formatMessage({
        id: `${NAMESPACE}.details.title`,
      })}
    >
      <Grid
        columnGap="size-350" // This is intentionally different from XD as it was advised to narrow the column gap
        columns={['size-1250', 'size-3000']}
        marginTop="size-100"
        rowGap="size-200"
        rows={['auto']}
      >
        {detailsFields.map(({key, value}) => (
          <React.Fragment key={key}>
            <Text data-testid={`${key}-key`} UNSAFE_style={textStyle}>
              {key}
            </Text>
            <Text data-testid={`${key}-value`}>
              {getValue({intl, namespace: `common.${NAMESPACE}`, value})}
            </Text>
          </React.Fragment>
        ))}
      </Grid>
    </DetailSection>
  );
};

export default Details;
