import {feature} from '@admin-tribe/acsc';
import {ModalDialog, OverlayWait, useStore} from '@admin-tribe/acsc-ui';
import {
  Button,
  ButtonGroup,
  Content,
  DialogContainer,
  Divider,
  Flex,
  Heading,
  useDialogContainer,
} from '@adobe/react-spectrum';
import {useId} from '@react-aria/utils';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import DismissChangesAlertModal from 'features/settings/common/components/dismiss-changes-alert-modal/DismissChangesAlertModal';
import SaveChangesAlertModal from 'features/settings/common/components/save-changes-alert-modal/SaveChangesAlertModal';
import {useDirectoryContext} from 'features/settings/components/directory/DirectoryContext';
import {DirectorySetupContextProvider} from 'features/settings/components/directory/DirectorySetupContext';
import SetupDirectoryAuthentication from 'features/settings/components/setup-directory-authentication/SetupDirectoryAuthentication';
import SetupDirectoryAuthenticationSteps from 'features/settings/components/setup-directory-authentication/SetupDirectoryAuthenticationSteps';
import useDirectorySetup from 'features/settings/hooks/directory-setup/useDirectorySetup';
import {useDirectoryDetailsContext} from 'features/settings/pages/directory-details-page/DirectoryDetailsContext';
import DirectorySetupStore, {
  SETUP_AUTHENTICATION_STEPS,
  SETUP_SAML_CERTS,
  SETUP_SECTIONS,
} from 'features/settings/stores/DirectorySetupStore';

const MODAL_ID = 'setup-directory-authentication-modal';

const MODALS = {
  DISMISS_CHANGES: 'dismiss-changes-modal',
  SAVE_CHANGES: 'save-changes-modal',
};

const SetupDirectoryAuthenticationModal = observer(({idp}) => {
  const {directoryStore} = useDirectoryContext();
  // We initialize the context to an empty object to make sure we can destructure
  // the reloadDirectory function, even if it's undefined
  const {reloadDirectory} = useDirectoryDetailsContext();
  const dialog = useDialogContainer();
  const modalHeadingId = useId();
  const directorySetupStore = useStore(() => new DirectorySetupStore(directoryStore));
  const [isEditingSoidcIdp, setIsEditingSoidcIdp] = useState(false);
  const [modal, setModal] = useState(null);
  const [stepIfDismissed, setStepIfDismissed] = useState(null);

  // Initializes the setup directory store with the correct idp
  useEffect(() => {
    directorySetupStore.setIdp(idp);
  }, [directorySetupStore, idp]);

  const {
    saveIdp,
    saveJitSettings,
    setupAuthenticationStepManager,
    ctaButtonLabelId,
    isCtaButtonDisabled,
    isDoingExternalAuth,
    loginToAzure,
    restoreAzureIdpCreation,
  } = useDirectorySetup(directorySetupStore, SETUP_SECTIONS.SETUP_AUTHENTICATION.id);

  const hasSamlCertsStep = feature.isEnabled('temp_saml_certs') && directorySetupStore.idp?.isSaml;

  // This modal is used to edit an IdP, so mark it accordingly
  useEffect(() => {
    directorySetupStore.isEditingIdp = true;
  }, [directorySetupStore]);

  // Skip the IdP configuration if the current IdP is of type SOIDC
  useEffect(() => {
    if (directorySetupStore.idp?.isSoidc) {
      setupAuthenticationStepManager.goNext();

      // SOIDC type IdPs can only have their JIT settings configured
      setIsEditingSoidcIdp(true);
    }
  }, [directorySetupStore.idp?.isSoidc, setupAuthenticationStepManager]);

  // restores a login from azure if an update tenant id was detected
  useEffect(() => {
    const [restoredAzureIdpCreation, azureData] = restoreAzureIdpCreation();

    if (restoredAzureIdpCreation) {
      directorySetupStore.isEditingAzureIdp = true;
      directorySetupStore.newTenantId = feature.isEnabled('temp_external_login_refactor')
        ? azureData.queryData.tenant
        : azureData.queryData.get('tenant');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- this should only run once on mount
  }, []);

  const modalTitleId = useMemo(() => {
    if (setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id) {
      return 'settings.setupDirectoryAuthenticationModal.editIdp';
    }

    if (setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id) {
      return 'settings.setupDirectoryAuthenticationModal.editJit';
    }

    return 'settings.setupDirectoryAuthenticationModal.editIdp';
  }, [setupAuthenticationStepManager.currentStep.id]);

  const onEditAzureIdp = () => {
    loginToAzure({isEditingAzureIdp: true});
  };

  const onCta = async () => {
    if (setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_IDP.id) {
      const isSuccessful = await saveIdp();

      if (isSuccessful) {
        setupAuthenticationStepManager.goNext();
        reloadDirectory();
      }
    }

    if (setupAuthenticationStepManager.currentStep.id === SETUP_AUTHENTICATION_STEPS.SETUP_JIT.id) {
      if (directorySetupStore.hasJitChanges) {
        const isSuccessful = await saveJitSettings();

        if (isSuccessful) {
          reloadDirectory();

          if (hasSamlCertsStep) {
            setupAuthenticationStepManager.goNext();
          } else {
            dialog.dismiss();
          }
        }
      } else if (hasSamlCertsStep) {
        setupAuthenticationStepManager.goNext();
      } else {
        dialog.dismiss();
      }
    }

    if (setupAuthenticationStepManager.currentStep.id === SETUP_SAML_CERTS.id) {
      dialog.dismiss();
    }
  };

  const onConfirmSaveChanges = () => {
    setModal(null);
    onCta();
  };

  const onCancelSaveChanges = () => {
    setModal(null);
    dialog.dismiss();
  };

  const onDismissChanges = () => {
    directorySetupStore.revertChanges();
    setupAuthenticationStepManager.goToStep(stepIfDismissed);

    setModal(null);
    setStepIfDismissed(null);
  };

  const onCancel = () => {
    if (
      directorySetupStore.hasJitChanges ||
      (directorySetupStore.hasIdpChanges && !isCtaButtonDisabled)
    ) {
      setModal(MODALS.SAVE_CHANGES);
    } else {
      dialog.dismiss();
    }
  };

  const onStepClick = (step) => {
    if (
      directorySetupStore.hasJitChanges ||
      (directorySetupStore.hasIdpChanges && !isCtaButtonDisabled)
    ) {
      setModal(MODALS.DISMISS_CHANGES);
      setStepIfDismissed(step);
    } else {
      setupAuthenticationStepManager.goToStep(step);
    }
  };

  return (
    <>
      <ModalDialog height="100vh" id={MODAL_ID}>
        <Heading data-testid="modal-title" id={modalHeadingId} level={2}>
          <FormattedMessage id={modalTitleId} />
        </Heading>

        <Divider />

        <Content data-testid="content">
          <DirectorySetupContextProvider directorySetupStore={directorySetupStore}>
            <OverlayWait
              isLoading={directoryStore.isUpdatingIdp || isDoingExternalAuth}
              showContent
            >
              {/* TODO: Remove `SetupDirectoryAuthentication` file when temp_saml_certs_wizard is removed */}
              {directorySetupStore.idp && feature.isDisabled(`temp_saml_certs_wizard`) && (
                <SetupDirectoryAuthentication
                  currentStep={setupAuthenticationStepManager.currentStep}
                  onLoginToAzure={onEditAzureIdp}
                />
              )}

              {directorySetupStore.idp && feature.isEnabled(`temp_saml_certs_wizard`) && (
                <SetupDirectoryAuthenticationSteps
                  currentStep={setupAuthenticationStepManager.currentStep}
                  isStepListInteractive={!isEditingSoidcIdp}
                  onLoginToAzure={onEditAzureIdp}
                  onStepChange={onStepClick}
                />
              )}
            </OverlayWait>
          </DirectorySetupContextProvider>
        </Content>

        <ButtonGroup justifySelf="space-between">
          <Flex flex={1}>
            <Button
              data-testid="prev-btn"
              isDisabled={!setupAuthenticationStepManager.canGoPrevious() || isEditingSoidcIdp}
              onPress={() => setupAuthenticationStepManager.goPrevious()}
              variant="secondary"
            >
              <FormattedMessage id="settings.setupDirectoryAuthenticationModal.previous" />
            </Button>
          </Flex>
          <Flex flex={1} justifyContent="end">
            <Button data-testid="cancel-button" onPress={onCancel} variant="primary">
              <FormattedMessage id="settings.setupDirectoryAuthenticationModal.cancel" />
            </Button>

            <Button
              data-testid="cta-button"
              isDisabled={isCtaButtonDisabled}
              onPress={onCta}
              variant="accent"
            >
              <FormattedMessage id={ctaButtonLabelId} />
            </Button>
          </Flex>
        </ButtonGroup>
      </ModalDialog>

      <DialogContainer data-testid="save-changes-modal" onDismiss={onCancelSaveChanges}>
        {modal === MODALS.SAVE_CHANGES && (
          <SaveChangesAlertModal onConfirm={onConfirmSaveChanges} />
        )}
      </DialogContainer>

      <DialogContainer data-testid="dismiss-changes-modal" onDismiss={() => setModal(null)}>
        {modal === MODALS.DISMISS_CHANGES && (
          <DismissChangesAlertModal onConfirm={onDismissChanges} />
        )}
      </DialogContainer>
    </>
  );
});

export default SetupDirectoryAuthenticationModal;
