import {feature} from '@admin-tribe/acsc';
import {OverlayWait} from '@admin-tribe/acsc-ui';
import {Divider, Provider, useDialogContainer} from '@adobe/react-spectrum';
import {
  ModalButtonGroup,
  ModalContent,
  ModalDialog,
  ModalHeading,
} from '@pandora/react-modal-dialog';
import Provider2 from '@react/react-spectrum/Provider';
import {useId} from '@react-aria/utils';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {FEDERATED_TYPES} from 'features/settings/api/ims-federated';
import DomainEnforcementInlineWarnings from 'features/settings/components/directory/domain-enforcement/components/DomainEnforcementInlineWarnings';
import FederatedDomainsTable2 from 'features/settings/components/import-federated-domains-modal/FederatedDomainsTable2';
import FederatedDomainsTableListWrapper from 'features/settings/components/import-federated-domains-modal/FederatedDomainsTableListWrapper';
import ImportFederatedDomainsModalAlert from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModalAlert';
import {ImportFederatedDomainsModalContextProvider} from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModalContext';
import useFederatedDomainsModalState from 'features/settings/components/import-federated-domains-modal/hooks/useFederatedDomainsModalState';

import './ImportFederatedDomainsModal.pcss';

const MODAL_ID = 'import-federated-domains-modal';

const ImportFederatedDomainsModal2 = ({
  authSourceId,
  domainEnforcement,
  externalToken,
  federatedType,
  onImportSuccess,
}) => {
  const intl = useIntl();

  const state = useFederatedDomainsModalState({
    authSourceId,
    externalToken,
    federatedType,
  });

  const modalHeadingId = useId();
  const dialog = useDialogContainer();

  // Get the list of federated domains
  useEffect(() => {
    state.loadFederatedDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run once during load
  }, []);

  const handleOnCta = useCallback(async () => {
    const response = await state.claimFederatedDomains();

    if (response) {
      onImportSuccess();
      dialog.dismiss();
    }
  }, [dialog, onImportSuccess, state]);

  const dialogTitle =
    federatedType === FEDERATED_TYPES.AZURE
      ? 'settings.directoryDomains.addAzureDomainsTitle'
      : 'settings.directoryDomains.addGoogleDomainsTitle';
  const dialogDescription =
    federatedType === FEDERATED_TYPES.AZURE
      ? 'settings.directoryDomains.addAzureDomainsDescription'
      : 'settings.directoryDomains.addGoogleDomainsDescription';

  return (
    <ModalDialog height="100vh" id={MODAL_ID}>
      <ModalHeading id={modalHeadingId}>{intl.formatMessage({id: dialogTitle})}</ModalHeading>
      <Divider />
      <ModalContent>
        {/* This is needed for the v2 components to render properly */}
        <Provider2>
          <Provider>
            <p>{intl.formatMessage({id: dialogDescription})}</p>
            {state.hasLoadingError ? (
              <OverlayWait isLoading={state.isLoading} size="M">
                <ImportFederatedDomainsModalAlert
                  error={state?.error}
                  onDataReload={() => state.loadFederatedDomains()}
                />
              </OverlayWait>
            ) : (
              <>
                {feature.isEnabled('temp_domain_enforcement') && (
                  <DomainEnforcementInlineWarnings
                    activatedTextKey="settings.directoryDomains.addDomains.warning.domainEnforcement"
                    enforcedTextKey="settings.directoryDomains.addDomains.warning.requireEmailChange"
                    marginBottom="size-200"
                    status={domainEnforcement?.state}
                  />
                )}
                <ImportFederatedDomainsModalContextProvider state={state}>
                  <OverlayWait isLoading={state.isLoading} showContent>
                    {feature.isEnabled('temp_pandora_refactor_federated_domains') ? (
                      <FederatedDomainsTableListWrapper
                        directoryId={authSourceId}
                        orgId={rootStore.organizationStore.activeOrgId}
                      />
                    ) : (
                      <FederatedDomainsTable2 />
                    )}
                  </OverlayWait>
                </ImportFederatedDomainsModalContextProvider>
              </>
            )}
          </Provider>
        </Provider2>
      </ModalContent>
      <ModalButtonGroup
        cancelLabel={intl.formatMessage({id: 'common.modal.buttons.cancel'})}
        ctaLabel={intl.formatMessage({id: 'settings.directoryDomains.confirm'})}
        isCtaDisabled={state.isCtaDisabled}
        onCancel={() => dialog.dismiss()}
        onCta={handleOnCta}
      />
    </ModalDialog>
  );
};

ImportFederatedDomainsModal2.propTypes = {
  /**
   * The ID of the Directory
   */
  authSourceId: PropTypes.string,
  domainEnforcement: PropTypes.shape({
    /**
     * The ID of the Directory
     */
    authSrc: PropTypes.string.isRequired,
    /**
     * Date when opt-out phase ends
     */
    notifyAt: PropTypes.number,
    /**
     * Current status of the Policy
     */
    state: PropTypes.string.isRequired,
    /**
     *  Date when the current state phase ends
     */
    stateEndsAt: PropTypes.number,
  }),
  /**
   * Federated external token to login to Azure/Google
   */
  externalToken: PropTypes.string,
  /**
   * Federated type (AZURE or GOOGLE)
   */
  federatedType: PropTypes.string,
  /**
   * Callback function when claiming domains is successful
   */
  onImportSuccess: PropTypes.func,
};

export default ImportFederatedDomainsModal2;
