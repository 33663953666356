import binkySrc2, {
  CART_EVENT,
  FeaturesCache,
  MEMBER_EVENT,
  authentication,
  eventBus,
  feature,
} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import {initApiServices} from 'common/api/apiServices';
import rootStore from 'core/RootStore';
import {useConfiguration} from 'core/providers/configuration/ConfigurationProvider';
import {useOrganization} from 'core/providers/organization/OrganizationProvider';
import ExternalAuthService from 'features/settings/services/ExternalAuthService';

import AppConstants from '../../../common/services/AppConstants';
import initializeHelp from '../../../shell/help/get-started/initializeHelp';
import useFraudMitigation from '../../fraudMitigation/useFraudMitigation';
import useObservability from '../../observability/useObservability';
import AutoAssignRulesCache from '../../services/product/AutoAssignRulesCache';
import {useAppLoadingOverlay} from '../AppLoadingOverlayProvider';

import generateGlobalBanners from './generateGlobalBanners';

const AppInitializationStateContext = createContext();

/**
 * Provides RootStore data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */

const AppInitializationStateProvider = ({children}) => {
  const [appInitialized, setAppInitialized] = useState(false);
  const [autoAssignRulesCacheLoaded, setAutoAssignRulesCacheLoaded] = useState(false);
  const {configuration} = useConfiguration();
  const {organizationsLoaded} = useOrganization();
  const {hideLoadingOverlay} = useAppLoadingOverlay();
  const {observabilityLoaded} = useObservability();

  const handleError = useErrorHandler();

  const [hasError, setHasError] = useState(false);

  const initializeFeaturesCache = useCallback(
    async ({countryCode, orgId}) => {
      const featuresCache = new FeaturesCache({
        contractList: rootStore.organizationStore.contractList,
        envConfig: configuration,
        initCountryCode: countryCode,
        initOrgId: orgId,
        productList: rootStore.organizationStore.productList,
      });
      await featuresCache.executeVendors();
    },
    [configuration]
  );

  const initializeAutoAssignRulesCache = useCallback(
    async (orgId) => {
      const autoAssignRulesCache = new AutoAssignRulesCache(configuration, orgId);
      await autoAssignRulesCache.refresh();
      setAutoAssignRulesCacheLoaded(true);
    },
    [configuration]
  );

  const initializeAuthAppConstants = useCallback(() => {
    const orgId = rootStore.organizationStore.activeOrg.id;
    const countryCode = rootStore.organizationStore.activeOrg.countryCode;
    const accessToken = authentication.getAccessToken()?.token;
    AppConstants.init({accessToken, configuration, countryCode, orgId});
  }, [configuration]);

  const triggerAppWideBannersAnalytics = useCallback(() => {
    binkySrc2.services.analytics.analyticsUtils.dispatchPageAnalytics({
      name: 'wideBanner',
    });
  }, []);

  useFraudMitigation();

  const registerHandlers = useCallback(() => {
    eventBus.registerEventHandler((eventId) => {
      const shouldRefreshProducts = [
        CART_EVENT.SUBMIT,
        MEMBER_EVENT.CREATE,
        MEMBER_EVENT.UPDATE,
      ].includes(eventId);
      const shouldRefreshContracts = eventId === CART_EVENT.SUBMIT;

      const refreshProducts = () =>
        shouldRefreshProducts
          ? rootStore.organizationStore.updateProductListSingleton()
          : Promise.resolve();

      const refreshContracts = () => {
        if (shouldRefreshContracts) {
          if (feature.isEnabled('temp_contract_info_mini_app')) {
            return rootStore.organizationStore.updateContractListSingleton({forceRefresh: true});
          }
          return rootStore.organizationStore.updateContractListSingleton();
        }
        return Promise.resolve();
      };

      // eslint-disable-next-line promise/catch-or-return -- Don't do anything if the promise fails
      Promise.all([refreshProducts(), refreshContracts()])
        // eslint-disable-next-line promise/prefer-await-to-then -- Event handler will become an async method
        .then(() => {
          if (
            feature.isDisabled('temp_refresh_fg_with_additional_context') ||
            (!shouldRefreshContracts && !shouldRefreshProducts)
          ) {
            return;
          }

          const context = rootStore.organizationStore.getOrganizationContext();
          feature.refresh({floodgateQueryParams: context});
        });
    });
  }, []);

  // init application services
  useEffect(() => {
    if (!organizationsLoaded) return;

    const countryCode = rootStore.organizationStore.activeOrg.countryCode;
    const orgId = rootStore.organizationStore.activeOrg.id;

    async function initializeAppData() {
      try {
        await initializeFeaturesCache({countryCode, orgId});
        hideLoadingOverlay();
        initializeAuthAppConstants();
        ExternalAuthService.init();
        initApiServices();
        initializeAutoAssignRulesCache(orgId);
        triggerAppWideBannersAnalytics();
        generateGlobalBanners();
        initializeHelp();
        registerHandlers();
        setAppInitialized(true);
      } catch (error) {
        setHasError(true);
        handleError(error);
      }
    }

    initializeAppData();
  }, [
    hideLoadingOverlay,
    initializeAutoAssignRulesCache,
    initializeFeaturesCache,
    handleError,
    initializeAuthAppConstants,
    registerHandlers,
    triggerAppWideBannersAnalytics,
    organizationsLoaded,
  ]);

  // configure observability custom attributes
  useEffect(() => {
    if (!organizationsLoaded) return;

    const configureObservabilityCustomAttributes = () => {
      if (feature.isEnabled('temp_observability')) {
        const contractList = rootStore.organizationStore.contractList;

        const contractAttributes = {
          ContractCount: contractList.items.length,
          ContractDirect: contractList.items.some((contract) => contract.isDirectContract()),
          ContractEnterprise: contractList.items.some((contract) =>
            contract.isEnterpriseContract()
          ),
          ContractIndirect: contractList.items.some((contract) => contract.isIndirectContract()),
          OrgId: rootStore.organizationStore.activeOrg.id,
          React: true,
          UseReactFlag: localStorage.getItem('useReactFlag'),
        };

        if (observabilityLoaded) {
          const {observabilityMetrics} = binkySrc2.services.observability;
          observabilityMetrics.setCustomAttributes(contractAttributes);
        }
      }
    };
    configureObservabilityCustomAttributes();
  }, [organizationsLoaded, observabilityLoaded]);

  const contextValue = useMemo(
    () => ({appInitialized, autoAssignRulesCacheLoaded}),
    [appInitialized, autoAssignRulesCacheLoaded]
  );

  return (
    !hasError &&
    organizationsLoaded && (
      <AppInitializationStateContext.Provider value={contextValue}>
        {children}
      </AppInitializationStateContext.Provider>
    )
  );
};

AppInitializationStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAppInitializationState = () => useContext(AppInitializationStateContext);

export {AppInitializationStateProvider, useAppInitializationState};
