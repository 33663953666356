/* eslint-disable max-lines -- this file requires more lines */
import {Locale, authentication, configStore} from '@admin-tribe/acsc';
import axios from 'axios';

import rootStore from 'core/RootStore';

let config, sessionStartTimestamp;

(async function loadConfig() {
  config = await configStore.getServiceConfiguration('packages');
  sessionStartTimestamp = Date.now();
})();

// ///////// API Utility methods ////////////

/**
 *
 * Retrieve rest api header
 *
 * @return {Object} rest api request id header
 */
function getRestApiHeaderObject() {
  return {
    Authorization: `Bearer ${authentication.getAccessToken().token}`,
    tron_request_id: `${sessionStartTimestamp}_${Date.now()}`,
    'x-api-key': config.config.apiKey,
    'X-Bearer-Org': rootStore.organizationStore.activeOrg.id,
    'X-Gar-Enabled': 'true',
    'X-Locale': Locale.get().activeLanguage,
  };
}

// ///////// REST resources ////////////

// Need to look at the query params and data these api's are getting called with
/**
 * Retrieves user preferences
 *
 * @param {Object} queryParams query parameters
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUserPreferences(queryParams) {
  return axios.get(`${getTronEndpoint()}/api/user/preference`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * Stores user preferences
 *
 * @param {Object} preferences data to be stored/posted
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function storeUserPreferences(preferences) {
  return axios.post(`${getTronEndpoint()}/api/user/preference`, preferences, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * Retrieves Organization preferences
 *
 * @param {Object} queryParams query parameters
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getOrgPreferences(queryParams) {
  return axios.get(`${getTronEndpoint()}/api/org/preference`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * Stores Organization preferences
 *
 * @param {Object} preferences data to be stored/posted
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function storeOrgPreferences(preferences) {
  return axios.post(`${getTronEndpoint()}/api/org/preference`, preferences, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * Retrieves SAT download url
 *
 * @param {Object} queryParams query parameters
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getSATDownloadUrl(queryParams) {
  return axios.get(`${getTronEndpoint()}/api/admin-tools/download-sat`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * Retrieves user specific adobe products
 *
 * @param {Object} queryParams query parameters
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUserSpecificAdobeProducts(queryParams) {
  return axios.get(`${getTronEndpoint()}/api/user/adobe_products`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * Retrieves user specific adobe templates
 *
 * @param {Object} queryParams - contains information that indicates if org is an enterprise org
 * @param {Boolean} queryParams.isEnterprise - indicates if the org is an enterprise org
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUserSpecificAdobeTemplates(queryParams) {
  return axios.get(`${getTronEndpoint()}/api/user/package_templates`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * @description Retrieves lan servers
 * @param {Object} cancelTokenServersSource - source object of cancel token
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getLanServers(cancelTokenServersSource) {
  return axios.get(`${getTronEndpoint()}/api/servers`, {
    cancelToken: cancelTokenServersSource.token,
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Deletes lan servers
 *
 * @param {Array} selectedServerIds - list of server ids to be deleted
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function deleteServers(selectedServerIds) {
  return axios.post(`${getTronEndpoint()}/api/servers/delete`, selectedServerIds, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Ato Generation Progress
 *
 * @param {String} serverId - id of server whose ato generation progress is fetched
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAtoGenerationProgress(serverId) {
  return axios.get(`${getTronEndpoint()}/api/servers/ato/progress/${serverId}`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Ato Download Url
 *
 * @param {String} serverId - id of server whose ato download url is fetched
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAtoDownloadUrl(serverId) {
  return axios.get(`${getTronEndpoint()}/api/servers/ato/download/${serverId}`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Auth Report Upload Progress
 *
 * @param {String} serverId - id of server whose auth report upload progress is fetched
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAuthReportUploadProgress(serverId) {
  return axios.get(`${getTronEndpoint()}/api/servers/authreport/progress/${serverId}`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Auth Report Upload Progress
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAuthReportUploadUrl() {
  return axios.get(`${getTronEndpoint()}/api/servers/authreport/upload`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Auth Report Upload Progress
 *
 * @param {String} serverId - id of the server fetched
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getServer(serverId) {
  return axios.get(`${getTronEndpoint()}/api/servers/${serverId}`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description send auth report upload success message to backend
 *
 * @param {String} id auth report Id
 * @param {Object} queryParams indicates serverId of the server
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function postAuthReportUploadSuccess(id, queryParams) {
  return axios.get(`${getTronEndpoint()}/api/servers/authreport/${id}`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * @description Stores Server Create Request
 *
 * @param {Object} serverCreateData
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function postServerCreateRequest(serverCreateData) {
  return axios.post(`${getTronEndpoint()}/api/servers`, serverCreateData, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Stores reauthorize Server Request
 *
 * @param {String} serverId,
 * @param {Object} serverCreateData
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function reauthorizeServer(serverId, serverCreateData) {
  return axios.post(`${getTronEndpoint()}/api/servers/reauth/${serverId}`, serverCreateData, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Stores update Server Request
 *
 * @param {String} serverId,
 * @param {Object} serverCreateData
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function updateServer(serverId, serverCreateData) {
  return axios.put(`${getTronEndpoint()}/api/servers/${serverId}`, serverCreateData, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Deletes Packages
 *
 * @param {Object} selectedPackagesIds
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function deletePackages(selectedPackagesIds) {
  return axios.post(`${getTronEndpoint()}/api/package/delete`, selectedPackagesIds, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Admin Packages
 *
 * @param {Object} cancelTokenServersSource
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getAdminPackages(queryParams, cancelTokenPackagesSource) {
  return axios.get(`${getTronEndpoint()}/api/package`, {
    cancelToken: cancelTokenPackagesSource.token,
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * @description Retrieves Customizer Progress For Package
 *
 * @param {Object} selectedPackagesId
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getCustomizerProgress(selectedPackageId) {
  return axios.get(`${getTronEndpoint()}/api/package/progress/${selectedPackageId}`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description Retrieves Downloader URL for Package
 *
 * @param {Object} selectedPackagesId
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getDownloaderUrl(selectedPackageId, queryParams) {
  return axios.get(`${getTronEndpoint()}/api/package/downloader/${selectedPackageId}`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * Retrieves user info
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUserInfo() {
  return axios.get(`${getTronEndpoint()}/api/user/info`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * @description  Retrieves Download URL for Package
 *
 * @param {Object} selectedPackagesId
 * @param {Object} queryParams query parameters
 * @param {boolean} queryParams.isNativePkg - indicates if download URL is required for native package
 * @param {boolean} queryParams.getCDN - indicates if CDN Url is required or not for native package
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getDownloadUrl(selectedPackageId, queryParams) {
  return axios.get(`${getTronEndpoint()}/api/package/download/${selectedPackageId}`, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * @description Stores Package Customization Report
 *
 * @param {Object} messageData message data
 * @param {Object} queryParams indicates if user specific apis
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function postPackageCustomizationRequest(messageData, queryParams) {
  return axios.post(`${getTronEndpoint()}/api/package/create`, messageData, {
    headers: getRestApiHeaderObject(),
    params: queryParams,
  });
}

/**
 * @description Retry Building Package
 *
 * @param {Object} selectedPackagesId
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function retryBuildingPackage(selectedPackageId) {
  return axios.get(`${getTronEndpoint()}/api/package/retry/${selectedPackageId}`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 * Retrieves all user specific Adobe Products for create package from backend
 *
 * @return {Promise<AxiosResponse<any>>} Axios response
 */
function getUserSpecificAdobeProductsToShowResource() {
  return axios.get(`${getTronEndpoint()}/api/user/adobe_product_to_show`, {
    headers: getRestApiHeaderObject(),
  });
}

/**
 *
 * Retrieve rest api url
 *
 * @return {string} rest api url
 */
function getTronEndpoint() {
  return config.url;
}

export {
  getRestApiHeaderObject,
  getUserPreferences,
  getOrgPreferences,
  storeUserPreferences,
  storeOrgPreferences,
  getSATDownloadUrl,
  getUserSpecificAdobeProducts,
  getUserSpecificAdobeTemplates,
  getUserInfo,
  getLanServers,
  deleteServers,
  getAtoGenerationProgress,
  getAtoDownloadUrl,
  getAuthReportUploadProgress,
  getAuthReportUploadUrl,
  getServer,
  postAuthReportUploadSuccess,
  postServerCreateRequest,
  reauthorizeServer,
  updateServer,
  deletePackages,
  getAdminPackages,
  getCustomizerProgress,
  getDownloaderUrl,
  getDownloadUrl,
  postPackageCustomizationRequest,
  retryBuildingPackage,
  getUserSpecificAdobeProductsToShowResource,
  getTronEndpoint,
};
/* eslint-enable max-lines -- this file requires more lines */
