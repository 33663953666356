import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {SOIDC_NAMES} from 'features/settings/entities/IdpEntity';

import useIdpProtocol from './useIdpProtocol';

// eslint-disable-next-line @admin-tribe/admin-tribe/jsdoc-exported-functions -- neculaes@ to update
const useIdpName = (idp) => {
  const intl = useIntl();
  const protocol = useIdpProtocol(idp);

  const name = useMemo(() => {
    if (!idp) {
      return intl.formatMessage({id: 'settings.authentication.sniffedProviders.unknown'});
    }

    let provider = '';

    const sniffedProviderTranslationId = `settings.authentication.sniffedProviders.${idp.sniffedProvider}`;
    const isKnownSniffedProvider = !!intl.messages[sniffedProviderTranslationId];

    // This check is necessary for the situation where a new sniffed provider is added
    // on the backend side, but no localization key is updated yet
    if (isKnownSniffedProvider) {
      provider = intl.formatMessage({id: sniffedProviderTranslationId});
      return `${provider} ${protocol}`; // Google SAML
    }

    if (idp.isAzure) {
      provider = intl.formatMessage({id: 'settings.authentication.providerAzure'});
      return `${provider} ${protocol}`; // Azure OIDC
    }

    if (idp.isOkta) {
      provider = intl.formatMessage({id: 'settings.authentication.providerDeprecated'});
      return `${protocol} ${provider}`; // SAML Provider (Deprecated)
    }

    if (idp.isSoidc) {
      provider = intl.formatMessage({
        id: `settings.authentication.nameProviders.${idp.providerName}`,
      });
      // Display only the provider name from IMS for EDU providers with the exception of
      // Google and Wonde that translates into Google/Wonde OIDC as to distinguish it from Google SAML
      return `${provider}${
        idp.providerName === SOIDC_NAMES.GOOGLE || idp.providerName === SOIDC_NAMES.WONDE
          ? ` ${protocol}`
          : ``
      }`;
    }

    provider = intl.formatMessage({id: 'settings.authentication.provider'});
    return `${protocol} ${provider}`; // SAML/OIDC/Unknown Provider
  }, [intl, idp, protocol]);

  return name;
};

export default useIdpName;
