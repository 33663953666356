// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {log} from '@admin-tribe/acsc';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import {JsonPayloadProvider} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaErrorBoundary from 'common/components/sophia/sophia-promo/SophiaErrorBoundary';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoContent} from 'common/services/sophia/utils/utils';
import SophiaRightRailPromoSection from 'features/overview/components/sophia-right-rail-section/SophiaRightRailPromoSection';

const SophiaRightRailPromoView = ({isHidden}) => {
  const [sophiaContent, setSophiaContent] = useState(null);
  const [sophiaAnalytics, setSophiaAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const surfaceId = SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_MERCHANDISING;

  // call sophia to get the content
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Resolve the initial payload
        const sophiaPayload = await sophiaHelper.getSophiaContent({surfaceId});

        // if no payload content, do nothing, component will not render
        if (
          !sophiaPayload ||
          !Array.isArray(sophiaPayload.items) ||
          !sophiaPayload.items[0]?.content
        ) {
          return;
        }

        // get the payload data
        const sophiaPayloadItem = sophiaPayload.items[0];

        // set the transformed content
        const content = mapPromoContent(JSON.parse(sophiaPayloadItem.content));
        setSophiaContent(content);

        // set the analytics data
        const analytics = sophiaHelper.getSophiaPromoAnalytics(sophiaPayloadItem, content);
        setSophiaAnalytics(analytics);

        // dispatch analytics
        sophiaHelper.dispatchSophiaPromoLoadEvent(analytics);
      } catch (error_) {
        setError(error_);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [surfaceId]);

  if (loading) {
    return null;
  }

  if (error) {
    log.error('Error resolving or transforming payload:', error);
    return null;
  }

  return (
    <SophiaErrorBoundary>
      <JsonPayloadProvider analytics={sophiaAnalytics} payload={sophiaContent}>
        <SophiaRightRailPromoSection isHidden={isHidden} />
      </JsonPayloadProvider>
    </SophiaErrorBoundary>
  );
};

SophiaRightRailPromoView.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaRightRailPromoView;
