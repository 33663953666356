import {GoUrl} from '@admin-tribe/acsc-ui';
import {Heading, Item, Picker, Text} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import EduIdpChooser from 'features/settings/components/edu-idp-chooser/EduIdpChooser';

const AddOidcToDirectory = ({
  directories,
  onDirectorySelected,
  defaultProviderName,
  onProviderSelected,
}) => {
  const intl = useIntl();

  return (
    <>
      <Text>
        <FormattedMessage
          id="settings.directories.addSoidcModal.description"
          values={{
            goUrl: (linkText) => <GoUrl name="oidc_sso_edu">{linkText}</GoUrl>,
          }}
        />
      </Text>

      <Heading level={4} marginBottom="size-100">
        <FormattedMessage id="settings.directories.addSoidcModal.enableSso" />
      </Heading>

      <Picker
        data-testid="directory-picker"
        defaultSelectedKey={directories.length === 1 ? directories[0].id : undefined}
        isQuiet={directories.length === 1}
        items={directories}
        label={intl.formatMessage({id: 'settings.directories.addSoidcModal.picker.label'})}
        onSelectionChange={onDirectorySelected}
      >
        {(directory) => <Item>{directory.directoryName}</Item>}
      </Picker>

      <EduIdpChooser
        defaultSelectedProvider={defaultProviderName}
        onSelectProvider={onProviderSelected}
      />
    </>
  );
};

AddOidcToDirectory.propTypes = {
  defaultProviderName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types -- the value comes from angular as well
  directories: PropTypes.array,
  onDirectorySelected: PropTypes.func,
  onProviderSelected: PropTypes.func,
};

export default AddOidcToDirectory;
