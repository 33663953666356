import {translateString} from '@admin-tribe/acsc-ui';

import {isMacTypePlatform} from 'features/packages/adminToolsUtils';
import {PACKAGE_TYPE_CONSTANTS} from 'features/packages/packagesConstants';

/**
 * @description Method to check if we need to show NPD Id in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show NPD Id, false otherwise
 */
const showNpdId = (attributes) =>
  attributes.package &&
  (attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_HIGH_PRIVACY_ONLINE ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_LAN ||
    attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_OFFLINE) &&
  !attributes.isTemplate &&
  !!attributes.npdId;

/**
 * @description Method to check if we need to show Activation Type in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show Activation Type, false otherwise
 */
const showActivationType = (attributes) => !attributes.isTemplate;

/**
 * @description Method to check if we need to show Server Details in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show Server Details, false otherwise
 */
const showServerDetails = (attributes) =>
  !attributes.isTemplate &&
  attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_LAN &&
  attributes.package.frlPackagingInfo;

/**
 * @description Method to check if FRL LAN activation codes were provided while making package
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show codes, false otherwise
 */
const showCodes = (attributes) =>
  !attributes.isTemplate && attributes.package.packageType === PACKAGE_TYPE_CONSTANTS.FRL_ISOLATED;

/**
 * @description Method to check if need to show packaging style in package summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show packaging style, false otherwise
 */
const showPackagingStyle = (attributes) => {
  if (attributes.package) {
    return isMacTypePlatform(attributes.package.platform);
  }
  return attributes.template && isMacTypePlatform(attributes.template.platform);
};

/**
 * @description Method to get packaging style to be shown in package summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {String} 'Flat' if admin package style is flat else 'Bundle'
 */
const getPackagingStyle = (attributes) =>
  attributes.package?.flatPackageEnabled || attributes.template?.nativePackageId
    ? translateString({id: 'packages.summaryDrawerBody.packageDetails.packagingStyle.flat'})
    : translateString({id: 'packages.summaryDrawerBody.packageDetails.packagingStyle.bundle'});

/**
 * @description Method to get SHA-256 checksum of native package
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {String} SHA-256 checksum of native package
 */
const getNativePackageSHA256 = (attributes) => attributes.template?.nativePackageSHA256;

/**
 * @description Method to check if need to show native package SHA256 checksum in package summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show, false otherwise
 */
const showNativePackageSHA256 = (attributes) => {
  if (attributes.template?.nativePackageId && attributes.template.nativePackageSHA256) {
    return true;
  }
  return false;
};

/**
 * @description Method to get native package size in gigabyte
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Number} native package size
 */
const getNativePackageSize = (attributes) =>
  (Number(attributes.template?.nativePackageSize) / 1024 / 1024 / 1024).toFixed(2);

/**
 * @description Method to check if need to show native package size in package summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show, false otherwise
 */
const showNativePackageSize = (attributes) => {
  if (attributes.template?.nativePackageId && getNativePackageSize(attributes) > 0) {
    return true;
  }
  return false;
};

/**
 * @description Method to get the alignment of Entitlement field in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {String} 'center' when there is only one entitlement, 'start' when there are multiple entitlements
 */
const getEntitlementAlignment = (attributes) =>
  attributes.package &&
  attributes.package.frlPackagingInfo &&
  attributes.package.frlPackagingInfo.adminPackageLicenseInfo &&
  attributes.package.frlPackagingInfo.adminPackageLicenseInfo.length === 1
    ? 'center'
    : 'start';

/**
 * @description Method to check if we need to show Entitlements in summary drawer
 * @param {Object} attributes - attributes object used in summary drawer
 * @returns {Boolean} True if we need to show Entitlements, false otherwise
 */
const showEntitlements = (attributes) =>
  !attributes.isTemplate &&
  attributes.package &&
  attributes.package.isFrlOrNuellPackage &&
  attributes.package.frlPackagingInfo;

export {
  showActivationType,
  showCodes,
  showEntitlements,
  showNativePackageSHA256,
  showNativePackageSize,
  showNpdId,
  showPackagingStyle,
  showServerDetails,
  getEntitlementAlignment,
  getNativePackageSize,
  getNativePackageSHA256,
  getPackagingStyle,
};
