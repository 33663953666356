import {
  ACTIONBLOCK_ID,
  CAMPAIGN_ID,
  CONTAINER_ID,
  CONTROL_GROUP_ID,
  SURFACE_ID,
  TREATMENT_ID,
  VARIATION_ID,
} from '@admin-tribe/acsc';
import omitBy from 'lodash/omitBy';

/**
 * @description Logical representation of a sophia content.
 */
class SophiaContent {
  constructor(opts) {
    Object.assign(this, {
      containerAnalyticsParams: opts.containerAnalyticsParams,
      containerId: opts.containerId,
      containerLabel: opts.containerLabel,
      content: opts.content,
      dataType: opts.dataType,
      surfaceId: opts.surfaceId,
    });
  }

  /**
   * @description filters the analytics values from the SophiaContent
   * @returns {Array} object containing a subset of values from API response object
   */
  getAnalyticsParams() {
    return omitBy(
      {
        actionBlockId: this.containerAnalyticsParams?.[ACTIONBLOCK_ID],
        campaignId: this.containerAnalyticsParams?.[CAMPAIGN_ID],
        containerId: this[CONTAINER_ID],
        controlGroupId: this.containerAnalyticsParams?.[CONTROL_GROUP_ID],
        surfaceId: this[SURFACE_ID],
        treatmentId: this.containerAnalyticsParams?.[TREATMENT_ID],
        variationId: this.containerAnalyticsParams?.[VARIATION_ID],
      },
      (item) => item === undefined
    );
  }

  /**
   * @description Returns the campaign ID from the analytics params of the Sophia content object
   * @returns {Object} The ID of the campaign.
   */
  getCampaignId() {
    return this.containerAnalyticsParams?.campaignId;
  }

  /**
   * @description Returns the container label.
   * @returns {string} The container label.
   */
  getContainerLabel() {
    return this.containerLabel;
  }

  /**
   * @description Returns the properties of a Sophia content object (omitting analytics info)
   * @returns {Object} properties for the content object.
   */
  getContent() {
    return this.content;
  }

  /**
   * @description Returns the data type of the content in “data” element
   * @returns {String} the data type for the content object.
   */
  getDataType() {
    return this.dataType;
  }

  /**
   * @description Checks whether the data type of the content is JSON
   * @returns {Boolean} true if data type is JSON, false otherwise.
   */
  isJson() {
    return this.dataType === 'application/json';
  }
}

export default SophiaContent;
