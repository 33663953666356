import {Heading} from '@adobe/react-spectrum';
import {ImageIcon} from '@pandora/react-image-icon';
import {Card} from '@react/react-spectrum/Card';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import {SOIDC_NAMES} from 'features/settings/entities/IdpEntity';

import './EduIdpCard.pcss';

const EduIdpCard = ({cardSize, logo, providerName, selected, title}) => {
  const styleName = classNames({
    'provider-card-logo': true,
    'schoology-card': providerName === SOIDC_NAMES.SCHOOLOGY,
    'wonde-card': providerName === SOIDC_NAMES.WONDE,
  });

  return (
    <Card
      allowsSelection={false}
      data-test-id={`${providerName.toLowerCase()}-card`}
      selected={selected}
      size="S"
      styleName="card"
    >
      <div styleName="card-body">
        <ImageIcon alt="" size={cardSize || 'XXXL'} src={logo} styleName={styleName} />
        <Heading marginBottom="0">
          <FormattedMessage id={title} />
        </Heading>
      </div>
    </Card>
  );
};

EduIdpCard.propTypes = {
  cardSize: PropTypes.string,
  logo: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default EduIdpCard;
