// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import PropTypes from 'prop-types';
import React, {createContext, useContext, useMemo} from 'react';

/**
 * This is a T&O experimental component, and as such may be removed or changed at any time. This component
 * may also be in use as part of an active A/B test or a Sophia campaign, Please consider this before making
 * any changes to this component or building any new features on top of this component. If there are any
 * questions about the component, please reach out to the T&O Engineering team in Edinburgh, we would be happy
 * to discuss this component, the use cases it is designed to solve, and the status of the experiment or
 * campaign that it is part of.
 *
 * This component that provides a context for some JSON inputPayload data. This is used for the case where you
 * can retrieve the data for a component from a json source (eg, Sophia), and are passing into a component where
 * there may be arbitrary levels of nesting for children in different versions of the component. The approach used
 * also allows for optional transformations to be registered with the context, which can be used to transform the
 * inputPayload for display purposes. An example may be pulling some data from Sophia that contains an offer
 * reference, and adding a transformer that resolves the offer reference to the actual offer data and pricing
 * from JIL or other services, before passing the transformed data to the children.
 *
 * In normal use, the 'payload' is the fully transformed inputPayload and should be the data referenced for display,
 * however the initial data is also returned, and an update function that will allow components that may wish to
 * adjust the initial data to do so. The transformer is not exposed and should not be manipulated by consumers of the
 * context, although the owner of the context could opt to use a compound pattern in the transform and add or remove
 * transformers as needed to the compound transformer passed in.
 *
 * @param children the children of the provider
 * @param payload the json value to provide to the children
 * @param analytics the analytics data to provide to the children
 * @returns {Element} the provider with the children
 * @constructor JsonPayloadProvider component that provides a context for JSON inputPayload data
 */
const JsonPayloadContext = createContext(null);

const JsonPayloadProvider = ({children, payload, analytics = {}}) => {
  const memoizedContext = useMemo(
    () => ({
      analytics,
      payload,
    }),
    [payload, analytics]
  );

  return (
    <JsonPayloadContext.Provider value={memoizedContext}>{children}</JsonPayloadContext.Provider>
  );
};

JsonPayloadProvider.propTypes = {
  analytics: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node.isRequired,
  payload: PropTypes.oneOfType([PropTypes.object]),
};

/**
 * Hook to use the JSON payload context. This should be used in components that need to access the
 * JSON payload data, and will throw an error if used outside the provider.
 * @returns {*}
 */
const useJsonPayload = () => {
  const context = useContext(JsonPayloadContext);

  if (!context) {
    throw new Error('useJsonPayload must be used within a JsonPayloadProvider');
  }

  return context;
};

export {JsonPayloadProvider, useJsonPayload};
