import {FederatedDomain} from '@pandora/data-model-ims-federated';
import {FederatedDomains} from '@pandora/react-federated-domain-list';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {useImportFederatedDomainsModalContext} from 'features/settings/components/import-federated-domains-modal/ImportFederatedDomainsModalContext';

const FederatedDomainsTableListWrapper = ({orgId, directoryId}) => {
  const {federatedDomainsList, setSelectedDomains} = useImportFederatedDomainsModalContext();

  const domains = useMemo(
    () =>
      federatedDomainsList?.map(
        (domain) =>
          new FederatedDomain({
            externalDomain: {name: domain.name},
            ...domain,
          })
      ),
    [federatedDomainsList]
  );

  return (
    federatedDomainsList?.length && (
      <FederatedDomains
        directoryId={directoryId}
        domains={domains}
        onDomainSelection={setSelectedDomains}
        orgId={orgId}
        showStatus
      />
    )
  );
};

FederatedDomainsTableListWrapper.propTypes = {
  /**
   * The ID of the Directory
   */
  directoryId: PropTypes.string,
  /**
   * The ID of the Organization
   */
  orgId: PropTypes.string,
};

export default FederatedDomainsTableListWrapper;
