import {Locale, MIGRATION_STATUS, MIGRATION_TYPE, feature} from '@admin-tribe/acsc';
import invokeMap from 'lodash/invokeMap';
import pick from 'lodash/pick';
import sum from 'lodash/sum';

import trialHelper from 'core/products/trial-helper/trialHelper';

import {CONTEXTUAL_PARAMS_SHARED} from './shared-contextual-params/SharedContextualParams';

/**
 * @description scrape contextual params from contract response.
 * @param {Contract} contract indirect or direct contract.
 * @returns {Object} contextual params formatted for T&T.
 */
function fromContractResponse(contract) {
  // Fetches the anniversary date in localised format
  const getFormattedAnniversaryDate = () => {
    const anniversaryDate = contract.getAnniversaryDate();
    const dateFormat = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    return anniversaryDate
      ? new Date(anniversaryDate).toLocaleDateString(Locale.get().activeLanguage, dateFormat)
      : '';
  };

  return {
    buyingProgram: contract.buyingProgram,
    comAutoRenewalMode: contract.autoRenewal?.mode ?? contract.autoRenewalMode,
    comDaysRemainingToRenew: contract.getDaysLeft() - 1,
    comRenewalDate: getFormattedAnniversaryDate(),
    contractAnniversaryDate: getDateInISOString(contract.getAnniversaryDate()),
    contractBillingFrequency: contract.billingInfo?.billingFrequency,
    contractCountry: contract.ownerInfo?.countryCode,
    contractCurrency: contract.billingInfo?.currency?.iso3code,
    contractCustomerSegment: contract.customerSegment,
    contractInRenewalWindow: contract.isInRenewalWindow(),
    contractModel: contract.model,
    contractPaymentCategory: contract.billingInfo?.paymentCategory,
    contractPlanType: contract.requestedPlanType,
    contractSalesChannel: contract.salesChannel,
  };
}

/**
 * @description scrape contextual params related to migration status
 * @param {Migration[]} migrations list containing migration status, start date, and type
 * @returns {Object} object containing contextual params with migrations information
 */
function fromMigrationsResponse(migrations) {
  return migrations.reduce((migrationsObj, migration) => {
    const type = migration?.type;
    const status = migration?.status;
    const startDate =
      status === MIGRATION_STATUS.MIGRATING ? migration?.metadata?.scheduledStartDate : undefined;
    switch (type) {
      case MIGRATION_TYPE.ESM_TYPE1:
        Object.assign(migrationsObj, {orgMigrationStatusESM: status});
        if (startDate) {
          Object.assign(migrationsObj, {orgMigrationESMStartDate: getDateInISOString(startDate)});
        }
        break;
      case MIGRATION_TYPE.T2E:
        Object.assign(migrationsObj, {orgMigrationStatusT2E: status});
        if (startDate) {
          Object.assign(migrationsObj, {orgMigrationT2EStartDate: getDateInISOString(startDate)});
        }
        break;
      case MIGRATION_TYPE.VIP2DIRECT:
        Object.assign(migrationsObj, {orgMigrationStatusVIP2DIRECT: status});
        if (startDate) {
          Object.assign(migrationsObj, {
            orgMigrationVIP2DIRECTStartDate: getDateInISOString(startDate),
          });
        }
        break;
      default:
        break;
    }
    return migrationsObj;
  }, {});
}

/**
 * @description scrape contextual params related to license count from products response.
 * @param {Products[]} productList list of products relevant to the contract.
 * @returns {Object} object containing contextual params related to license counts
 */
function fromProductsResponse(productListItems) {
  // eslint-disable-next-line lodash/chaining -- cannot chain
  const assignableLicenseCount = sum(invokeMap(productListItems, 'getAssignableLicenseCount'));

  const activeCount = sum(productListItems.map((product) => product.provisionedQuantity));

  const trialLicenseCount = sum(
    productListItems.map((product) => product.licenseTupleList.hasTrialLicenses())
  );

  return {
    '1pcl': [...new Set(productListItems.map((item) => item.code))].join(','),
    contractActiveLicenseCount: activeCount,
    contractActivePaidLicenseCount: assignableLicenseCount - trialLicenseCount,
    contractAvailableLicenseCount: assignableLicenseCount - activeCount,
    contractHasCreativeCloudStorage: productListItems.some(
      (product) =>
        product.fulfillableItemList.getCreativeCloudStorageChargingModelCap() > 0 ||
        (product.isCreativeCloudProduct() &&
          product.fulfillableItemList.getESMUserStorageChargingModelCap() > 0)
    ),
    contractLicenseCount: assignableLicenseCount,
  };
}

function handleTrialContract(trialContract, trialDurationDays, response) {
  // Since trialDaysFromStart and trialDaysSinceEnd are Number data types there are no
  // defaults. Send each only if it applies.
  if (trialContract) {
    const daysLeft = trialContract.getDaysLeft();
    if (Number.isInteger(daysLeft)) {
      if (trialContract.isStatusActive()) {
        response.trialDaysFromStart = trialDurationDays - daysLeft;
      } else if (trialContract.isStatusExpired()) {
        response.trialDaysSinceEnd = -daysLeft;
      }
    }
  }
}

/**
 * @deprecated Please use shared contextual params
 *
 * @description scrape contextual params from trial contract response.
 * @param {Contract} [trialContract] the trial contract.
 *   If undefined, default values will be returned.
 * @returns {Object} the contextual params for CONTEXTUAL_PARAMS_TRIAL_CONTRACT
 */
function fromTrialContractResponse(trialContract) {
  // If trialContract is undefined, this will return 0.
  let response;
  if (feature.isDisabled('temp_tno_simplify_sophia_parameters')) {
    const trialDurationDays = trialHelper.getTrialDuration(trialContract);

    response = {
      trialContractCountry: trialContract?.ownerInfo?.countryCode ?? '',
      trialContractModel: trialContract?.model ?? '',
      trialContractStatus: trialContract?.status ?? '',
      trialDurationDays,
    };
    handleTrialContract(trialContract, trialDurationDays, response);
  } else {
    response = {
      trialContractCountry: trialContract?.ownerInfo?.countryCode ?? '',
      trialContractModel: trialContract?.model ?? '',
      trialContractStatus: trialContract?.status ?? '',
    };
  }

  return response;
}

/**
 * @deprecated Please use shared contextual params
 *
 * @description scrape contextual params related to trial license count from products response.
 * @param {Products[]} [trialProductListItems] list of trial products relevant to the contract.
 *   If undefined, default values will be returned
 * @returns {Object} the contextual params for CONTEXTUAL_PARAMS_PRODUCT
 */
function fromTrialProductResponse(trialProductListItems) {
  // eslint-disable-next-line lodash/chaining -- cannot chain
  const assignableLicenseCount = sum(invokeMap(trialProductListItems, 'getAssignableLicenseCount'));
  const activeCount = sum(trialProductListItems.map((product) => product.provisionedQuantity));

  return {
    trialActiveLicenseCount: activeCount,
    trialAvailableLicenseCount: assignableLicenseCount - activeCount,
    trialLicenseCount: assignableLicenseCount,
    trialOfferIdList: trialProductListItems.map((product) => product.offerId).join(','),
    trialProductCodeList: trialProductListItems.map((product) => product.code).join(','),
  };
}

/**
 * @description reduce model to object containing params required by sophia API.
 * @param {SophiaContextualParams} object src model.
 * See [organization-level contextual params]{@link https://git.corp.adobe.com/admin-tribe/onesie/wiki/Integration-with-Sophia}
 * @returns {Object} object containing defined params.
 */
function toMinimumObject(object) {
  let CONTEXTUAL_PARAMS;
  if (feature.isDisabled('temp_tno_simplify_sophia_parameters')) {
    CONTEXTUAL_PARAMS = [
      'acctLanguage',
      'adminRoles',
      'ARRDroppedPercentage',
      'CommonOrgID',
      'contractCanAddLicenses',
      'hasAdminCompletedSecurityContactInfo',
      'organizationMarketSegment',
      'productLanguage',
      'showQuickAssignProducts',
      'teamBannerShown',
    ];

    if (feature.isEnabled('temp_sophia_promotional_offer')) {
      CONTEXTUAL_PARAMS.push('selectedCancellationReasons');
    }

    if (feature.isEnabled('temp_use_individual_product_hva_banner')) {
      CONTEXTUAL_PARAMS.push('rpid', 'rpf', 'rpala', 'rpalt');
    }
  } else {
    CONTEXTUAL_PARAMS = [
      'acctLanguage',
      'adminRoles',
      'ARRDroppedPercentage',
      'CommonOrgID',
      'hasAdminCompletedSecurityContactInfo',
      'organizationMarketSegment',
      'productLanguage',
      'showQuickAssignProducts',
    ];

    if (feature.isEnabled('temp_use_individual_product_hva_banner')) {
      CONTEXTUAL_PARAMS.push('rpid', 'rpf', 'rpala', 'rpalt');
    }
  }

  const CONTEXTUAL_PARAMS_CONTRACT = [
    'buyingProgram',
    'comAutoRenewalMode',
    'comDaysRemainingToRenew',
    'comRenewalDate',
    'contractAnniversaryDate',
    'contractBillingFrequency',
    'contractCountry',
    'contractCurrency',
    'contractCustomerSegment',
    'contractInRenewalWindow',
    'contractModel',
    'contractPaymentCategory',
    'contractPlanType',
    'contractSalesChannel',
    'isAdminContractOwner',
  ];

  const CONTEXTUAL_PARAMS_PRODUCT = [
    'contractActiveLicenseCount',
    'contractActivePaidLicenseCount',
    'contractAvailableLicenseCount',
    'contractHasCreativeCloudStorage',
    'contractLicenseCount',
    // shorterned from one console product code list for smaller Sophia payload
    '1pcl',
  ];

  const CONTEXTUAL_PARAMS_MIGRATION_STATUS = [
    'orgMigrationStatusESM',
    'orgMigrationStatusT2E',
    'orgMigrationStatusVIP2DIRECT',
    'orgMigrationESMStartDate',
    'orgMigrationT2EStartDate',
    'orgMigrationVIP2DIRECTStartDate',
  ];

  const selectedParams = [
    ...CONTEXTUAL_PARAMS,
    ...CONTEXTUAL_PARAMS_SHARED.filter((param) => param !== 'productFamilies'),
    ...CONTEXTUAL_PARAMS_CONTRACT,
    ...CONTEXTUAL_PARAMS_PRODUCT,
    ...CONTEXTUAL_PARAMS_MIGRATION_STATUS,
  ];

  return pick(object, selectedParams);
}

function getDateInISOString(dateInString) {
  return dateInString ? new Date(dateInString).toISOString() : '';
}

export {
  fromContractResponse,
  fromMigrationsResponse,
  fromProductsResponse,
  fromTrialContractResponse,
  fromTrialProductResponse,
  toMinimumObject,
};
