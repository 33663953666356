/* eslint-disable max-statements -- large file*/
/* eslint-disable max-lines  -- large file*/
import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/acsc';
import {CopyToClipboardButton} from '@admin-tribe/acsc-ui';
import {
  Checkbox,
  Flex,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Text,
  TextArea,
  TextField,
  View,
} from '@adobe/react-spectrum';
import {GoUrl} from '@pandora/react-go-url';
import {TooltipButton} from '@pandora/react-tooltip-button';
import AlertIcon from '@spectrum-icons/workflow/Alert';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import {CREATE_PACKAGE_CONSTANTS} from 'features/packages/packagesConstants';
import CreatePackageService from 'features/packages/services/CreatePackageService';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';
import PackagesPreferencesService from 'features/packages/services/PackagesPreferencesService';

import {useCreatePackageModalContext} from '../../CreatePackageModalContext';
import {shouldShowExtensionOptions} from '../self-service/selfServicePageUtils';

import ManagementOptionItem from './ManagementOptionItem';
import {isLightWeightPackage} from './ManagementOptionsPageUtils';
import DeviceViewConsent from './device-view-consent/DeviceViewConsent';

/* eslint-disable complexity -- Big form having lot of fields and components*/
// Management Options Screen in the Create Package Workflow
// Used to modify package parameters like priviledges, autoUpdate, fileSync, userInstallDirectory etc.
const ManagementOptionsPage = observer(
  () => {
    const intl = useIntl();
    const {createPackageModalStore} = useCreatePackageModalContext();
    const {
      packageCreateObj,
      pkgSessionSettings,
      selectedProducts,
      setPackageCreateObjValues,
      setPkgSessionSettingValues,
      isColdFusionEntitlementSelected,
    } = createPackageModalStore;

    const frlFixedIpReverseProxyServer = rootStore.packagesUiConstantsStore.frlStaticDnsEndpoint;
    const frlFixedIpAddressList = rootStore.packagesUiConstantsStore.frlFixedDnsIps;
    const frlFixedIpAddressListSplitted = frlFixedIpAddressList.join(', ');

    const [selected, setSelected] = useState(
      pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE &&
        pkgSessionSettings.adobeLicenseFixedDns
        ? CREATE_PACKAGE_CONSTANTS.FRL_FIXED_DNS
        : CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP
    );

    const [browserBasedAuthEnabled, setBrowserBasedAuthEnabled] = useState(
      packageCreateObj.browserBasedAuthEnabled
    );

    const [fileSyncDisabled, setFileSyncDisabled] = useState(!packageCreateObj.filesPanelEnabled);

    const [appPanelEnabled, setAppPanelEnabled] = useState(packageCreateObj.appPanelEnabled);

    const [accAdminPrivileged, setAccAdminPrivileged] = useState(
      packageCreateObj.accAdminPrivileged
    );

    const [accDisableAutoAppUpdate, setAccDisableAutoAppUpdate] = useState(
      packageCreateObj.accDisableAutoAppUpdate
    );

    const [selfServePluginsEnabled, setSelfServePluginsEnabled] = useState(
      packageCreateObj.selfServePluginsEnabled
    );

    const [betaAppDownloadEnabled, setBetaAppDownloadEnabled] = useState(
      packageCreateObj.betaAppDownloadEnabled
    );

    const [enableCustomDNS, setEnableCustomDNS] = useState(pkgSessionSettings.enableCustomDNS);

    const [adobeLicenseFixedDns, setAdobeLicenseFixedDns] = useState(
      pkgSessionSettings.adobeLicenseFixedDns
    );

    const [rumEnabled, setRumEnabled] = useState(packageCreateObj.rumEnabled);

    const [disableUpdate, setDisableUpdate] = useState(!packageCreateObj.updatesEnabled);

    const [ausstOverride, setAusstOverride] = useState(packageCreateObj.ausstOverride);

    const [enableExtenstions, setEnableExtenstions] = useState(packageCreateObj.enableExtenstions);

    const [userInstallDirectory, setuserInstallDirectory] = useState(
      packageCreateObj.userInstallDirectory
    );

    const [dnsUrlError, setDnsUrlError] = useState(
      !!pkgSessionSettings.customDNS &&
        !!pkgSessionSettings.customDNS.profileServerUrl &&
        pkgSessionSettings.customDNS.profileServerUrl.length > 0 &&
        !pkgSessionSettings.nextButtonEnabled
    );

    // update deviceInformationShared value to true in case of frl online only, irrespective of org type
    useEffect(() => {
      if (pkgSessionSettings.deviceInformationShared === undefined) {
        const deviceInformationShared =
          pkgSessionSettings.licenseFileSelected &&
          pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE &&
          pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE;
        setPkgSessionSettingValues('deviceInformationShared', deviceInformationShared);
      }
      if (
        feature.isEnabled('packages_disable_app_trial') &&
        pkgSessionSettings.showAccInUI &&
        packageCreateObj.disableAppTrial === undefined
      ) {
        setPackageCreateObjValues('disableAppTrial', false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to run on initial render
    }, []);

    // update management options values when platform changes
    useEffect(() => {
      setPkgSessionSettingValues(
        'ausstOverrideAvailable',
        !!PackagesPreferencesService.getAusstXml(packageCreateObj.platform)
      );

      setPkgSessionSettingValues(
        'userInstallDirectoryAvailable',
        !!PackagesPreferencesService.getUserInstallDirectory(packageCreateObj.platform)
      );
      // update extensions options when extensions options are not to be shown
      if (!shouldShowExtensionOptions(packageCreateObj.platform)) {
        setEnableExtenstions(false);
        setPackageCreateObjValues('enableExtenstions', false);
        setPkgSessionSettingValues('enableAalExtension', false);
      }
    }, [packageCreateObj.platform, setPackageCreateObjValues, setPkgSessionSettingValues]);

    function isFrlOnlinePackage() {
      return pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE;
    }

    function showFixedDnsIpOptions() {
      return isFrlOnlinePackage() && pkgSessionSettings.licenseFileSelected;
    }

    function onStaticDnsRadioSelectionChange(event) {
      if (event === CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP) {
        setPkgSessionSettingValues('adobeLicenseServerFixedIp', true);
        setAdobeLicenseFixedDns(false);
        setPkgSessionSettingValues('adobeLicenseFixedDns', false);
      } else {
        setAdobeLicenseFixedDns(true);
        setPkgSessionSettingValues('adobeLicenseFixedDns', true);
        setPkgSessionSettingValues('adobeLicenseServerFixedIp', false);
      }
      setSelected(event);
    }

    function isCopyToClipBoardButtonDisabled() {
      return !pkgSessionSettings.adobeLicenseServerFixedIp;
    }

    function showInstallDirectoryOption() {
      return (
        (PackagesEntitlements.hasCcEntitlement ||
          PackagesEntitlements.hasSubstanceEntitlement ||
          PackagesEntitlements.hasIndesignServerEntitlement) &&
        (pkgSessionSettings.showAccInUI ||
          pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE)
      );
    }

    function showBetaAppDownloadOption() {
      return (
        pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE ||
        pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE
      );
    }

    function isExtensionsCheckboxDisabled() {
      return (
        (isLightWeightPackage(pkgSessionSettings.packageType, selectedProducts.length) &&
          pkgSessionSettings.selectedPlugins?.length === 0) ||
        pkgSessionSettings.selectedPlugins?.length > 0
      );
    }

    function isShowInstallDirectoryOptionDisabled() {
      return (
        isLightWeightPackage(pkgSessionSettings.packageType, selectedProducts.length) ||
        !pkgSessionSettings.userInstallDirectoryAvailable
      );
    }

    function showSelfServePluginsSetting() {
      return (
        pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE &&
        rootStore.organizationStore.activeOrg.marketSegment !==
          ORGANIZATION_MARKET_SEGMENT.EDUCATION &&
        !(
          packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM &&
          packageCreateObj.bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
        ) &&
        packageCreateObj.platform !== CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM
      );
    }

    function updateAppsPanelSetting(selection) {
      setAppPanelEnabled(selection);
      setPackageCreateObjValues('appPanelEnabled', selection);
      if (!selection) {
        setAccAdminPrivileged(false);
        setPackageCreateObjValues('accAdminPrivileged', false);

        setAccDisableAutoAppUpdate(true);
        setPackageCreateObjValues('accDisableAutoAppUpdate', true);

        setBetaAppDownloadEnabled(false);
        setPackageCreateObjValues('betaAppDownloadEnabled', false);
      }
    }

    function onProfileServerUrlChange(selection) {
      const customDNS = {profileServerUrl: selection};
      setPkgSessionSettingValues('customDNS', customDNS);

      let isValidUrl = false;
      if (pkgSessionSettings.customDNS?.profileServerUrl?.length > 0) {
        if (packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM) {
          const macURLValidationRegex = /^(https:\/\/)?([\w./-]+)(:\d{2,5})?$/;
          isValidUrl = macURLValidationRegex.test(
            pkgSessionSettings.customDNS.profileServerUrl.toLowerCase()
          );
        } else {
          const winURLValidationRegex = /^(http(s)?:\/\/)?([\w./-]+)(:\d{2,5})?$/;
          isValidUrl = winURLValidationRegex.test(
            pkgSessionSettings.customDNS.profileServerUrl.toLowerCase()
          );
        }
      }

      setPkgSessionSettingValues('nextButtonEnabled', isValidUrl);
      setDnsUrlError(
        !!pkgSessionSettings.customDNS &&
          !!pkgSessionSettings.customDNS.profileServerUrl &&
          pkgSessionSettings.customDNS.profileServerUrl.length > 0 &&
          !pkgSessionSettings.nextButtonEnabled
      );
    }

    function handleCustomDNSCheckBoxToggle(selection) {
      setEnableCustomDNS(selection);
      setPkgSessionSettingValues('enableCustomDNS', selection);
      if (selection) {
        onProfileServerUrlChange();
      } else {
        setPkgSessionSettingValues('nextButtonEnabled', true);
      }
    }

    function updateAdminPrivilegeSetting(selection) {
      setAccAdminPrivileged(selection);
      setPackageCreateObjValues('accAdminPrivileged', selection);
      setAccDisableAutoAppUpdate(!selection);
      setPackageCreateObjValues('accDisableAutoAppUpdate', !selection);
    }

    function updateAutoUpdate(selection) {
      setAccDisableAutoAppUpdate(selection);
      setPackageCreateObjValues('accDisableAutoAppUpdate', selection);
    }

    function updateSelfServePluginsSetting(selection) {
      setSelfServePluginsEnabled(selection);
      setPackageCreateObjValues('selfServePluginsEnabled', selection);
    }

    function updateCoreSyncSetting(selection) {
      setFileSyncDisabled(selection);
      setPackageCreateObjValues('filesPanelEnabled', !selection);

      setPackageCreateObjValues('marketPanelEnabled', !packageCreateObj.marketPanelEnabled);
    }

    function updateBrowserBasedAuthEnabled(selection) {
      setBrowserBasedAuthEnabled(selection);
      setPackageCreateObjValues('browserBasedAuthEnabled', selection);
    }

    function updateBetaAppDownloadEnabled(selection) {
      setBetaAppDownloadEnabled(selection);
      setPackageCreateObjValues('betaAppDownloadEnabled', selection);
    }

    function updateRumEnabled(selection) {
      setRumEnabled(selection);
      setPackageCreateObjValues('rumEnabled', selection);
    }

    function toggleDisableUpdates(selection) {
      setDisableUpdate(selection);
      setPackageCreateObjValues('updatesEnabled', !selection);
    }

    function updateAusstOverride(selection) {
      if (pkgSessionSettings.ausstOverrideAvailable) {
        setAusstOverride(selection);
        setPackageCreateObjValues('ausstOverride', selection);
      }
    }

    function updateExtensionsSetting(selection) {
      setEnableExtenstions(selection);
      setPackageCreateObjValues('enableExtenstions', selection);

      if (selection) {
        setPkgSessionSettingValues('enableAalExtension', false);
      }
    }

    function updateUserInstallDirectory(selection) {
      if (pkgSessionSettings.userInstallDirectoryAvailable) {
        setuserInstallDirectory(selection);
        setPackageCreateObjValues('userInstallDirectory', selection);
      }
    }

    function getExtendedDescriptionForBetaAppDownload() {
      if (packageCreateObj.betaAppDownloadEnabled) {
        return intl.formatMessage({
          id: 'packages.createPackage.managementOptions.betaAppDownloadDesc2',
        });
      }
      return undefined;
    }

    function showDeviceInformationSection() {
      return (
        pkgSessionSettings.licenseFileSelected &&
        pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE &&
        pkgSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE &&
        rootStore.organizationStore.activeOrg.marketSegment !==
          ORGANIZATION_MARKET_SEGMENT.EDUCATION
      );
    }

    return (
      <>
        {/* Device view consent section*/}
        {showDeviceInformationSection() && (
          <DeviceViewConsent
            data-testid="device-view-consent"
            pkgSessionSettings={pkgSessionSettings}
            setPkgSessionSettingValues={setPkgSessionSettingValues}
          />
        )}
        {/* fixed dns and IP address*/}
        {showFixedDnsIpOptions() && (
          <View>
            <View marginBottom="size-300" marginTop="size-300">
              <Heading level={3}>
                {intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.staticDnsTitle',
                })}
              </Heading>
            </View>
            <RadioGroup
              aria-label="radio"
              isEmphasized
              marginTop="size-125"
              onChange={onStaticDnsRadioSelectionChange}
              value={selected}
            >
              <Radio value={CREATE_PACKAGE_CONSTANTS.FRL_FIXED_DNS}>
                <Text UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.adobeLicenseFixedDnsTitle',
                  })}
                </Text>
              </Radio>
              <View marginStart="size-300">
                <Text>
                  {/* eslint-disable-next-line @admin-tribe/admin-tribe/extract-large-computations -- component having text with url placeholder*/}
                  {intl.formatMessage(
                    {
                      id: 'packages.createPackage.managementOptions.adobeLicenseFixedDnsDesc',
                    },
                    {
                      link: (str) => (
                        <Link>
                          <a href="https://lcs-cops.adobe.io\" rel="noreferrer" target="_blank">
                            {str}
                          </a>
                        </Link>
                      ),
                    }
                  )}
                  {/* eslint-enable-next-line @admin-tribe/admin-tribe/extract-large-computations -- component having text with url placeholder*/}
                </Text>
                <GoUrl
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.adobeLicenseFixedDnsLearnMoreAriaLabel',
                  })}
                  marginStart="size-50"
                  name="ent_learn_fixed_dns_help"
                >
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.learnMoreAboutCreateFRLPackages',
                  })}
                </GoUrl>
              </View>
              <Radio value={CREATE_PACKAGE_CONSTANTS.FRL_FIXED_IP}>
                <Text UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.adobeLicenseServerFixedIpTitle',
                  })}
                </Text>
              </Radio>
              <View marginStart="size-300">
                <Text>
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.adobeLicenseServerFixedIpDesc',
                  })}
                </Text>
                <GoUrl
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.adobeLicenseServerFixedIpLearnMoreAriaLabel',
                  })}
                  marginStart="size-50"
                  name="ent_learn_fixed_ip_help"
                >
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.learnMoreAboutCreateFRLPackages',
                  })}
                </GoUrl>
              </View>
            </RadioGroup>
            <View marginStart="size-300" marginTop="size-300">
              <Flex direction="row">
                <Text>
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.fixedIpAddressList',
                  })}
                </Text>
                <Text>({frlFixedIpAddressList.length})</Text>
              </Flex>
              <Flex direction="row" gap="size-300">
                <TextArea
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.fixedIpAddressList',
                  })}
                  isReadOnly
                  value={frlFixedIpAddressListSplitted}
                  width="size-4600"
                />
                <View>
                  <CopyToClipboardButton
                    isDisabled={isCopyToClipBoardButtonDisabled()}
                    successMessage={intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.copyConfirmationForStaticIpAddress',
                    })}
                    value={String(frlFixedIpAddressList)}
                    variant="secondary"
                  >
                    {intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.copyFixedIpList',
                    })}
                  </CopyToClipboardButton>
                </View>
              </Flex>
            </View>
          </View>
        )}

        {/*  acc options here*/}
        {pkgSessionSettings.showCreatePackageUI && pkgSessionSettings.showAccInUI && (
          <>
            <View marginBottom="size-300" marginTop="size-300">
              <Heading level={3}>
                {intl.formatMessage({
                  id: 'packages.createPackage.step1.creativeCloudOptions',
                })}
              </Heading>
            </View>

            {feature.isEnabled('packages_disable_app_trial') && (
              <ManagementOptionItem
                data-testid="disable-app-trial"
                isSelected={packageCreateObj.disableAppTrial}
                managementOptionDesc={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.disableAppTrialsDesc',
                })}
                managementOptionTitle={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.disableAppTrialsTitle',
                })}
                onSelectionChange={() =>
                  setPackageCreateObjValues('disableAppTrial', !packageCreateObj.disableAppTrial)
                }
              />
            )}

            <ManagementOptionItem
              data-testid="appsPanel"
              isSelected={appPanelEnabled}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.appsPanelLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="ent_learnmore_showappspanel"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.appsPanelDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.appsPanelTitle',
              })}
              onSelectionChange={() => updateAppsPanelSetting(!appPanelEnabled)}
            />

            <ManagementOptionItem
              data-testid="adminPrivileges"
              isDisabled={!packageCreateObj.appPanelEnabled}
              isSelected={accAdminPrivileged}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.adminPrivilegesLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="ent_learnmore_elevatedprivileges"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.adminPrivilegesDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.adminPrivilegesTitle',
              })}
              onSelectionChange={() => updateAdminPrivilegeSetting(!accAdminPrivileged)}
            />

            <ManagementOptionItem
              data-testid="autoUpdate"
              isDisabled={!accAdminPrivileged}
              isSelected={!!accDisableAutoAppUpdate}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.autoAppUpdateLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="ent_learnmore_autoappupdate"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.autoAppUpdateDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.autoAppUpdateTitle',
              })}
              onSelectionChange={() => updateAutoUpdate(!accDisableAutoAppUpdate)}
            />

            {showSelfServePluginsSetting() && (
              <ManagementOptionItem
                data-testid="selfServePlugins"
                isSelected={selfServePluginsEnabled}
                learnMoreGoUrlAriaLabel={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.selfServePluginsLearnMoreAriaLabel',
                })}
                learnMoreGoUrlLink="ent_learnmore_selfserveplugins"
                managementOptionDesc={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.selfServePluginsDesc',
                })}
                managementOptionTitle={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.selfServePluginsTitle',
                })}
                onSelectionChange={() => updateSelfServePluginsSetting(!selfServePluginsEnabled)}
              />
            )}

            <Flex direction="column" marginBottom="size-125">
              <Flex direction="row">
                <Checkbox
                  data-testid="fileSync"
                  isEmphasized
                  isSelected={fileSyncDisabled}
                  onChange={() => updateCoreSyncSetting(!fileSyncDisabled)}
                >
                  <Text UNSAFE_style={{fontSize: 'size-175', fontWeight: 'bold'}}>
                    {intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.fileSyncTitle',
                    })}
                  </Text>
                </Checkbox>
                <TooltipButton
                  aria-label={intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.fileSyncTooltip',
                  })}
                  elementType="span"
                  hoverText={intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.fileSyncTooltip',
                  })}
                  isQuiet
                  minWidth="size-0"
                  variant="action"
                >
                  <InfoOutlineIcon />
                </TooltipButton>
              </Flex>
              <View>
                <Text marginStart="size-300">
                  {intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.fileSyncDesc',
                  })}
                </Text>
                {pkgSessionSettings.packagingMode !==
                  CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE && (
                  <GoUrl
                    aria-label={intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.fileSyncLearnMoreAriaLabel',
                    })}
                    marginStart="size-50"
                    name="ent_learnmore_disablefilesync"
                  >
                    {intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.learnMoreAboutCreateNULPackages',
                    })}
                  </GoUrl>
                )}
                {pkgSessionSettings.packagingMode ===
                  CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE && (
                  <GoUrl
                    aria-label={intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.fileSyncLearnMoreAriaLabel',
                    })}
                    marginStart="size-50"
                    name="ent_learnmore_disablefilesync_sdl"
                  >
                    {intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.learnMoreAboutCreateSDLPackages',
                    })}
                  </GoUrl>
                )}
              </View>
            </Flex>

            <ManagementOptionItem
              data-testid="browserBasedAuth"
              isSelected={browserBasedAuthEnabled}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.browserAuthLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="ent_learnmore_brow_auth"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.browserAuthDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.browserAuthTitle',
              })}
              onSelectionChange={() => updateBrowserBasedAuthEnabled(!browserBasedAuthEnabled)}
            />

            {showBetaAppDownloadOption() && (
              <ManagementOptionItem
                data-testid="betaAppDownload"
                isDisabled={!appPanelEnabled}
                isSelected={betaAppDownloadEnabled}
                learnMoreGoUrlAriaLabel={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.betaAppDownloadLearnMoreAriaLabel',
                })}
                learnMoreGoUrlLink="ent_learnmore_beta_app_download"
                managementOptionDesc={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.betaAppDownloadDesc',
                })}
                managementOptionExtendedDesc={getExtendedDescriptionForBetaAppDownload()}
                managementOptionTitle={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.betaAppDownloadTitle',
                })}
                onSelectionChange={() => updateBetaAppDownloadEnabled(!betaAppDownloadEnabled)}
              />
            )}
          </>
        )}

        {/*  note for feature flags*/}
        {isFrlOnlinePackage() && feature.isEnabled('temp_packages_feature_flag_note') && (
          <Flex data-testid="featureFlagNote" direction="column" marginTop="size-300">
            <Text UNSAFE_style={{fontSize: 'size-10', fontWeight: 'bold'}}>
              {intl.formatMessage({
                id: 'packages.createPackage.managementOptions.featureFlagsHeading',
              })}
            </Text>
            <View>
              <Text>
                {intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.featureFlagsInformation',
                })}
              </Text>
              <GoUrl
                aria-label={intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.learnMoreAboutFFDisablement',
                })}
                marginStart="size-50"
                name="ent_learnmore_frl_online_ff_note"
              >
                {intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.learnMoreAboutFFDisablement',
                })}
              </GoUrl>
            </View>
          </Flex>
        )}

        <View marginBottom="size-300" marginTop="size-300">
          <Heading level={3}>
            {intl.formatMessage({
              id: 'packages.createPackage.managementOptions.title',
            })}
          </Heading>
        </View>

        {/* Custom DNS end-point for FRL Connected */}
        {pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE &&
          pkgSessionSettings.licenseFileSelected && (
            <ManagementOptionItem
              data-testid="customDNS"
              isSelected={enableCustomDNS}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.customDNSLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="learn_custom_dns_help"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.customDNSDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.customDNSTitlePhase2',
              })}
              onSelectionChange={() => handleCustomDNSCheckBoxToggle(!enableCustomDNS)}
            />
          )}

        {pkgSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE &&
          pkgSessionSettings.licenseFileSelected &&
          enableCustomDNS && (
            <Flex direction="column" marginStart="size-300">
              <Flex alignItems="end" direction="row">
                <TextField
                  data-test-id="directory-name-input"
                  label={intl.formatMessage({
                    id: 'packages.createPackage.managementOptions.customDNSServerLabel',
                  })}
                  onChange={onProfileServerUrlChange}
                />
                {dnsUrlError && (
                  <TooltipButton
                    aria-label={intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.dnsError',
                    })}
                    data-testid="errorIcon"
                    hoverText={intl.formatMessage({
                      id: 'packages.createPackage.managementOptions.dnsError',
                    })}
                    isQuiet
                    variant="action"
                  >
                    <AlertIcon
                      UNSAFE_style={{fill: 'var(--spectrum-semantic-negative-color-icon)'}}
                    />
                  </TooltipButton>
                )}
              </Flex>
              <Flex direction="row" gap="size-50" marginBottom="size-125" marginTop="size-125">
                <InfoOutlineIcon color="informative" size="S" />
                {adobeLicenseFixedDns && (
                  <FormattedMessage
                    data-testid="management-options-page-formatted-message-1"
                    id="packages.createPackage.managementOptions.customDnsInfoText"
                    values={{
                      link: (url) => (
                        <Link data-testid="management-options-page-link-1">
                          <a href={url} rel="noopener noreferrer" target="_blank">
                            {url}
                          </a>
                        </Link>
                      ),
                      url: 'https://lcs-cops.adobe.io',
                    }}
                  />
                )}
                {!adobeLicenseFixedDns && (
                  <FormattedMessage
                    data-testid="management-options-page-formatted-message-2"
                    id="packages.createPackage.managementOptions.customDnsInfoText"
                    values={{
                      link: (url) => (
                        <Link data-testid="management-options-page-link-2">
                          <a href={url} rel="noopener noreferrer" target="_blank">
                            {url}
                          </a>
                        </Link>
                      ),
                      url: frlFixedIpReverseProxyServer,
                    }}
                  />
                )}
              </Flex>
            </Flex>
          )}

        {/*  rum options*/}
        {pkgSessionSettings.packageType !== CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE &&
          !isColdFusionEntitlementSelected && (
            <ManagementOptionItem
              data-testid="rumOption"
              isSelected={rumEnabled}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.enableRumLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="learn_rum_help"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.enableRumDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.enableRum',
              })}
              onSelectionChange={() => updateRumEnabled(!rumEnabled)}
            />
          )}

        {/*  enableUpdate options*/}
        {CreatePackageService.isNonCCTemplate() && (
          <ManagementOptionItem
            data-testid="updateOption"
            isSelected={disableUpdate}
            learnMoreGoUrlAriaLabel={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.disableUpdatesLearnMoreAriaLabel',
            })}
            learnMoreGoUrlLink="disable_update_help"
            managementOptionDesc={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.disableUpdatesDesc',
            })}
            managementOptionTitle={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.disableUpdates',
            })}
            onSelectionChange={() => toggleDisableUpdates(!disableUpdate)}
          />
        )}
        {/*  ausst options*/}
        {pkgSessionSettings.packageType !== CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE &&
          !isColdFusionEntitlementSelected && (
            <ManagementOptionItem
              data-testid="ausstOption"
              isDisabled={!pkgSessionSettings.ausstOverrideAvailable}
              isSelected={ausstOverride}
              learnMoreGoUrlAriaLabel={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.ausstLearnMoreAriaLabel',
              })}
              learnMoreGoUrlLink="learn_ausst_help"
              managementOptionDesc={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.ausstDesc',
              })}
              managementOptionTitle={intl.formatMessage({
                id: 'packages.createPackage.managementOptions.ausstTitle',
              })}
              onSelectionChange={() => updateAusstOverride(!ausstOverride)}
            />
          )}

        {/* Extensions options*/}

        {shouldShowExtensionOptions(packageCreateObj.platform) && (
          <ManagementOptionItem
            data-testid="extensions"
            isDisabled={isExtensionsCheckboxDisabled()}
            isSelected={enableExtenstions}
            learnMoreGoUrlAriaLabel={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.extensionsLearnMoreAriaLabel',
            })}
            learnMoreGoUrlLink="ent_exmanCmd_help"
            managementOptionDesc={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.extensionsTitleDesc',
            })}
            managementOptionTitle={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.extensionsTitle',
            })}
            onSelectionChange={() => updateExtensionsSetting(!enableExtenstions)}
          />
        )}

        {/* Custom install options*/}

        {showInstallDirectoryOption() && (
          <ManagementOptionItem
            data-testid="userInstallDirectory"
            isDisabled={isShowInstallDirectoryOptionDisabled()}
            isSelected={userInstallDirectory}
            learnMoreGoUrlAriaLabel={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.customDirectoryLearnMoreAriaLabel',
            })}
            learnMoreGoUrlLink="ent_learnmore_installcustomlocation"
            managementOptionDesc={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.customDirectoryDesc',
            })}
            managementOptionTitle={intl.formatMessage({
              id: 'packages.createPackage.managementOptions.customDirectoryTitle',
            })}
            onSelectionChange={() => updateUserInstallDirectory(!userInstallDirectory)}
          />
        )}
      </>
    );
  }
  /* eslint-enable complexity -- Big form having lot of fields and components*/
);

ManagementOptionsPage.propTypes = {};

export default ManagementOptionsPage;
/* eslint-enable max-statements -- large file*/
/* eslint-enable max-lines  -- large file*/
