import {feature} from '@admin-tribe/acsc';
import {Flex, Grid, View, VisuallyHidden} from '@adobe/react-spectrum';
import {DividerPlacement} from '@pandora/react-titled-section';
import React from 'react';
import {useIntl} from 'react-intl';
import {Outlet} from 'react-router-dom';

import useDocumentTitle from 'common/hooks/useDocumentTitle';
import usePageAnalytics from 'common/hooks/usePageAnalytics';
import rootStore from 'core/RootStore';
import AccountPagePreload from 'features/account/pages/AccountPagePreload';
import ResponsiveProvider from 'features/overview/shell/container/ResponsiveProvider';

import {
  HelpfulResourcesSection,
  HvaSummarySection,
  ModalManager,
  ProductsSummarySection,
  QuickLinkBannerSection,
  QuickLinksSection,
  SophiaBanner,
  SophiaRightRailPromoView,
  StorageSummarySection,
  UpdatesSummarySection,
  UsersSummarySection,
} from '../components';

/**
 * The Overview page
 */
const OverviewPage = () => {
  const contentGap = 'size-300'; // 24px
  const intl = useIntl();

  useDocumentTitle({key: 'overview.title'});
  usePageAnalytics({name: 'overview'});

  const useSimplifiedOverview =
    feature.isEnabled('temp_tno_overview_remove_ui') ||
    feature.isEnabled('temp_tno_overview_remove_ui_intl');

  const useNewQuickLinkSection =
    feature.isEnabled('temp_tno_new_quick_link_section') ||
    feature.isEnabled('temp_tno_new_quick_link_section_intl');

  return (
    <ResponsiveProvider>
      <ModalManager />
      <Flex data-testid="overview-page" justifyContent="center" width="100%">
        <VisuallyHidden>
          <h1>
            {intl.formatMessage(
              {id: 'overview.heading'},
              {orgName: rootStore.organizationStore.activeOrg.name}
            )}
          </h1>
        </VisuallyHidden>
        <Flex
          alignItems="center"
          data-testid="overview-content"
          direction="column"
          gap={contentGap}
          width="100%"
        >
          {useNewQuickLinkSection && <QuickLinkBannerSection />}

          <Grid
            // flexBasis="1440px" // must use px here, calc does not work
            gap={contentGap}
            paddingTop="60px"
            width="85%"
          >
            <Grid
              columns={{
                base: '1fr',
                L: '3fr 1fr',
              }}
              gap={contentGap}
            >
              <Flex
                data-testid="overview-content-main"
                direction="column"
                gap={contentGap}
                isHidden={{
                  base: false,
                  L: false,
                }}
              >
                <Grid
                  columns={{
                    base: '1fr',
                    // make columns equal width
                    L: 'minmax(0, 1fr) minmax(0, 1fr)',
                    M: 'minmax(0, 1fr) minmax(0, 1fr)',
                  }}
                  data-testid="overview-content-main"
                  gap={contentGap}
                >
                  <HvaSummarySection />
                  {!useNewQuickLinkSection && (
                    <QuickLinksSection
                      dividerPlacement={DividerPlacement.BOTTOM}
                      isHidden={{
                        L: true,
                        M: true,
                      }}
                    />
                  )}
                  <Flex direction="column" gap={contentGap}>
                    <ProductsSummarySection />
                    <SophiaRightRailPromoView
                      isHidden={{
                        M: false,
                      }}
                    />
                  </Flex>
                  <Flex direction="column" gap={contentGap}>
                    <UsersSummarySection />
                    {!useSimplifiedOverview && (
                      <>
                        <StorageSummarySection
                          isHidden={{
                            L: true,
                          }}
                        />
                        <UpdatesSummarySection
                          isHidden={{
                            L: true,
                          }}
                        />
                      </>
                    )}
                    <HelpfulResourcesSection
                      dividerPlacement={DividerPlacement.NONE}
                      isHidden={{
                        L: true,
                      }}
                    />
                  </Flex>
                  <SophiaBanner />
                  {!useSimplifiedOverview && (
                    <>
                      <StorageSummarySection
                        isHidden={{
                          base: true,
                          M: true,
                          S: true,
                        }}
                      />
                      <UpdatesSummarySection
                        isHidden={{
                          base: true,
                          M: true,
                          S: true,
                        }}
                      />
                    </>
                  )}
                </Grid>
              </Flex>
              <Flex
                data-testid="overview-content-sidebar"
                direction="column"
                gap={contentGap}
                isHidden={{
                  base: false,
                  L: false,
                }}
              >
                {!useNewQuickLinkSection && (
                  <QuickLinksSection
                    dividerPlacement={DividerPlacement.BOTTOM}
                    isHidden={{
                      base: true,
                      S: true,
                    }}
                  />
                )}
                <SophiaRightRailPromoView
                  isHidden={{
                    base: false,
                    L: false,
                    S: false,
                  }}
                />
                <HelpfulResourcesSection
                  dividerPlacement={DividerPlacement.BOTTOM}
                  isHidden={{
                    base: true,
                    M: true,
                    S: true,
                  }}
                />
              </Flex>
              {/* Spacer to prevent footer from covering content - remove once there is a global fix in the shell */}
              <View height="size-2000" />
            </Grid>
          </Grid>
        </Flex>
        {/* arbitrary location to render Outlet since currently known child routes are inside a modal */}
        {feature.isEnabled('temp_standalone_quick_assign') && <Outlet />}
        {/* arbitrary location to render account page preload to prefetch data for optimization */}
        {feature.isEnabled('temp_account_data_optimization') && <AccountPagePreload />}
      </Flex>
    </ResponsiveProvider>
  );
};

OverviewPage.propTypes = {};

export default OverviewPage;
