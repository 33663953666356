// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {INTERACTION, hasDomain, log} from '@admin-tribe/acsc';
import {Button, Flex} from '@adobe/react-spectrum';
import React, {useState} from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import useGlobalModal from 'common/hooks/useGlobalModal';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

const HELP_SEARCH_ALLOWLIST_DOMAINS = ['adobe.com', 'frame.io'];

const SophiaPromoActions = () => {
  const {payload, analytics} = useJsonPayload();

  const isValidAdobeUrl = (url) => {
    if (url) {
      if (HELP_SEARCH_ALLOWLIST_DOMAINS.some((domain) => hasDomain(url, domain))) {
        return true;
      }
      log.error(`Invalid non-Adobe domain: ${url}`);
    } else {
      log.error('Sophia card given undefined or empty URL');
    }
    return false;
  };

  const [modalComponent, setModalComponent] = useState(null);
  const {openModal} = useGlobalModal(modalComponent);

  // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- disabled for resolving merge conflicts
  // istanbul ignore next
  if (!payload) {
    return null;
  }

  const primaryCTAAction = payload.primaryCTAAction;
  const primaryCTALabel = payload.primaryCTALabel;
  const secondaryCTAAction = payload.secondaryCTAAction;
  const secondaryCTALabel = payload.secondaryCTALabel;

  const shouldShowButton = (label, url) => label && isValidAdobeUrl(url);
  const primaryCTACallback = getCtaClickEventCallback(primaryCTAAction);
  const secondaryCTACallback = getCtaClickEventCallback(secondaryCTAAction);

  const showPrimaryButton = shouldShowButton(primaryCTALabel, primaryCTAAction);
  const showSecondaryButton = shouldShowButton(secondaryCTALabel, secondaryCTAAction);

  if (!showPrimaryButton && !showSecondaryButton) {
    return null;
  }

  const onPrimaryActionPress = () => {
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.PRIMARY,
    });
    primaryCTACallback?.();
    setModalComponent(primaryCTACallback?.());
    openModal();
  };

  const onSecondaryActionPress = () => {
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.SECONDARY,
    });
    secondaryCTACallback?.();
  };

  return (
    <Flex
      alignItems="start"
      data-testid="one-console-promo-actions"
      direction={{base: 'column', M: 'row'}}
      gap="size-200"
    >
      {showSecondaryButton && (
        <Button
          data-testid="one-console-secondary-cta-button"
          height="auto"
          onPress={onSecondaryActionPress}
        >
          {secondaryCTALabel}
        </Button>
      )}
      {showPrimaryButton && (
        <Button
          data-testid="one-console-primary-cta-button"
          height="auto"
          onPress={onPrimaryActionPress}
          variant="accent"
        >
          {primaryCTALabel}
        </Button>
      )}
    </Flex>
  );
};

SophiaPromoActions.propTypes = {};

export default SophiaPromoActions;
