// eslint-disable-next-line eslint-comments/disable-enable-pair -- could not reduce complexity due to multiple checks
/* eslint-disable complexity -- could not reduce complexity due to multiple checks */
import {ORGANIZATION_MARKET_SEGMENT, feature} from '@admin-tribe/acsc';
import {Grid, Text} from '@adobe/react-spectrum';
import isMatch from 'lodash/isMatch';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import rootStore from 'core/RootStore';
import PackagesEntitlements from 'features/packages/services/PackagesEntitlementsService';

import {CREATE_PACKAGE_CONSTANTS} from '../../../../../packagesConstants';
import CreatePackageService from '../../../../../services/CreatePackageService';
import style from '../FinalizePackagePage.pcss';

const PackageDetailOptions = observer(({packageSessionSettings, packageCreateObj}) => {
  const intl = useIntl();

  /**
   * @description Method to check if we should show the ausst option in the summary
   * @returns {Boolean} true/false
   */
  const showAusstOption = () =>
    !(packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE);

  /**
   * @description Method to check if we should show the extensions option in the summary
   * @returns {Boolean} true/false
   */
  const showExtenstionsOption = () => {
    if (
      (feature.isDisabled('temp_packages_acrobat_only_flow') &&
        !PackagesEntitlements.hasDcOnlyEntitlement &&
        (packageSessionSettings.showAccInUI ||
          packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE)) ||
      (feature.isEnabled('temp_packages_acrobat_only_flow') &&
        (PackagesEntitlements.hasCcEntitlement || PackagesEntitlements.hasDcEntitlement))
    ) {
      if (packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM) {
        return true;
      }
      if (packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM) {
        // checking if packages_extensions_winarm flag is set only if selected platform is WIN ARM type
        return feature.isEnabled('packages_extensions_winarm');
      }
      // show the extensions option for all other platform
      return true;
    }
    return false;
  };

  /**
   * @description Method to check if we should show the user install directory option
   * @returns {Boolean} true/false
   */
  const showInstallDirectoryOption = () =>
    (!PackagesEntitlements.hasDcOnlyEntitlement || PackagesEntitlements.hasSubstanceEntitlement) &&
    (packageSessionSettings.showAccInUI ||
      packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE);

  /**
   * @description Method to check if we should show plugins install option in summary screen
   * @returns {Boolean} true/false
   */
  const showSelfServePluginsOption = () =>
    // should not show plugins install option if showAccInUI flag is off
    packageSessionSettings.showAccInUI &&
    // should not show plugins install option if packagingMode is not named package type
    packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE &&
    // should not show plugins install option if selected platform is 32 bits WIN
    !rootStore.organizationStore.isActiveOrgEdu &&
    !(
      packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM &&
      packageCreateObj.bits === CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT
    ) &&
    // should not show plugins install option if selected platform is WINARM and packages_extensions_winarm flag is disabled
    !(
      packageCreateObj.platform === CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM &&
      feature.isDisabled('packages_extensions_winarm')
    );

  /**
   * @description Method to check if we should show plugins install option in summary screen
   * @returns {Boolean} true/false
   */
  const showDisableAutoAppUpdateOption = () => !!packageSessionSettings.showAccInUI;

  /**
   * @description Method to check if we should show the beta app download option
   * @returns {Boolean} true/false
   */
  const showBetaAppDownloadOption = () =>
    // show beta app download option if packagingMode is nuell package or named package
    (packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NUELL_PACKAGE_MODE ||
      packageSessionSettings.packagingMode === CREATE_PACKAGE_CONSTANTS.NAMED_PACKAGE_TYPE) &&
    // show beta app download option if showAccInUI is set to true
    packageSessionSettings.showAccInUI;

  const isDeviceInformationShared = () =>
    rootStore.organizationStore.activeOrg.marketSegment !== ORGANIZATION_MARKET_SEGMENT.EDUCATION &&
    isMatch(packageSessionSettings, {
      deviceInformationShared: true,
      licenseFileSelected: true,
      packageType: CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE,
      packagingMode: CREATE_PACKAGE_CONSTANTS.FRL_PACKAGE_TYPE,
    });

  const showDisableUpdateOption = () => CreatePackageService.isNonCCTemplate();

  return (
    <Grid columns={['2fr', '3fr']} gap="size-100" marginBottom="size-100" marginTop="size-100">
      <Text>{intl.formatMessage({id: 'packages.createPackage.finalizePackagePage.options'})}</Text>
      <ul className={style['options-list']} color="gray-800">
        {isDeviceInformationShared() && (
          <li data-testid="device-information-shared">
            {intl.formatMessage({
              id: 'packages.createPackage.managementOptions.deviceInformationShared',
            })}
          </li>
        )}
        {packageSessionSettings.showAccInUI && (
          <>
            {packageCreateObj.disableAppTrial && (
              <li data-testid="disable-app-trial">
                {intl.formatMessage({
                  id: 'packages.createPackage.managementOptions.disableAppTrialsTitle',
                })}
              </li>
            )}

            <li data-testid="show-app-panel">
              {intl.formatMessage({
                id: `packages.createPackage.finalizePackagePage.${
                  packageCreateObj.appPanelEnabled ? 'showAppsPanel' : 'disableAppsPanel'
                }`,
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: `packages.createPackage.finalizePackagePage.${
                  packageCreateObj.accAdminPrivileged
                    ? 'enableAdminPrivileges'
                    : 'disableAdminPrivileges'
                }`,
              })}
            </li>
          </>
        )}
        {showDisableAutoAppUpdateOption() && (
          <li data-testid="disable-auto-update">
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.accDisableAutoAppUpdate.${packageCreateObj.accDisableAutoAppUpdate}`,
            })}
          </li>
        )}
        {showSelfServePluginsOption() && (
          <li data-testid="plugins-install-summary">
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.selfServePluginsEnabled.${packageCreateObj.selfServePluginsEnabled}`,
            })}
          </li>
        )}
        {showBetaAppDownloadOption() && (
          <li data-testid="enable-beta-app-download">
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.enableBetaAppDownload.${packageCreateObj.betaAppDownloadEnabled}`,
            })}
          </li>
        )}
        {packageSessionSettings.showAccInUI && (
          <>
            <li data-testid="enable-core-sync">
              {intl.formatMessage({
                id: `packages.createPackage.finalizePackagePage.${
                  packageCreateObj.filesPanelEnabled ? 'enableCoreSync' : 'disableCoreSync'
                }`,
              })}
            </li>
            <li data-testid="enable-browser-based-auth">
              {intl.formatMessage({
                id: `packages.createPackage.finalizePackagePage.${
                  packageCreateObj.browserBasedAuthEnabled
                    ? 'enableBrowserBasedAuth'
                    : 'disableBrowserBasedAuth'
                }`,
              })}
            </li>
          </>
        )}
        {packageSessionSettings.packageType === CREATE_PACKAGE_CONSTANTS.FRL_ONLINE_PACKAGE && (
          <>
            {packageSessionSettings.adobeLicenseFixedDns && (
              <li data-testid="enable-fixed-dns">
                {intl.formatMessage({
                  id: `packages.createPackage.finalizePackagePage.enableFixedDnsLicenseServer`,
                })}
              </li>
            )}
            {packageSessionSettings.adobeLicenseServerFixedIp && (
              <li data-testid="enable-fixed-ip">
                {intl.formatMessage({
                  id: `packages.createPackage.finalizePackagePage.enableFixedIpLicenseServer`,
                })}
              </li>
            )}
            {packageSessionSettings.enableCustomDNS && (
              <li data-testid="enable-custom-dns">
                {`${intl.formatMessage({
                  id: 'packages.createPackage.finalizePackagePage.enableCustomDNSServer',
                })} ${packageSessionSettings.customDNS.profileServerUrl}`}
              </li>
            )}
          </>
        )}
        {packageSessionSettings.packageType !== CREATE_PACKAGE_CONSTANTS.FRL_ISOLATED_PACKAGE && (
          <li data-testid="enable-rum">
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.${
                packageCreateObj.rumEnabled ? 'enableRUM' : 'disableRUM'
              }`,
            })}
          </li>
        )}
        {showExtenstionsOption() && (
          <li data-testid="extenstion-value">
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.${
                packageCreateObj.enableExtenstions ? 'enableExtenstions' : 'disableExtensions'
              }`,
            })}
          </li>
        )}

        <li data-testid="assetLink-value">
          {intl.formatMessage({
            id: `packages.createPackage.finalizePackagePage.${
              packageSessionSettings.enableAalExtension ? 'enableAssetLink' : 'disableAssetLink'
            }`,
          })}
        </li>

        {showAusstOption() && (
          <li>
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.${
                packageCreateObj.ausstOverride ? 'ausstXmlIncluded' : 'ausstXmlNotIncluded'
              }`,
            })}
          </li>
        )}

        {showInstallDirectoryOption() && (
          <li data-testid="show-install-directory-option">
            {intl.formatMessage({
              id: `packages.createPackage.finalizePackagePage.${
                packageCreateObj.userInstallDirectory
                  ? 'userInstallDirectoryIncluded'
                  : 'userInstallDirectoryNotIncluded'
              }`,
            })}
          </li>
        )}

        {showDisableUpdateOption() && (
          <li data-testid="disable-update">
            {intl.formatMessage({
              id: packageCreateObj.updatesEnabled
                ? `packages.createPackage.managementOptions.enabledUpdates`
                : `packages.createPackage.managementOptions.disableUpdates`,
            })}
          </li>
        )}
      </ul>
    </Grid>
  );
});

PackageDetailOptions.propTypes = {
  /**
   * Function to open create server page
   */
  packageCreateObj: PropTypes.shape({
    packageName: PropTypes.string,
  }).isRequired,
  /**
   * Shared object for package creation session
   */
  packageSessionSettings: PropTypes.shape({
    frlSelectedServer: PropTypes.instanceOf(Object),
  }).isRequired,
};

export default PackageDetailOptions;
