import {getCustomerType} from 'features/packages/services/createPackageServiceUtils';

import {
  CREATE_PACKAGE_CONSTANTS,
  PACKAGE_TYPE_CONSTANTS,
  PRODUCT_SAPCODES,
} from '../packagesConstants';
import PackagesIngestAnalyticsService from '../services/PackagesIngestAnalyticsService';
/**
 * @description Method to check if the template contains only Acrobat
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains only Acrobat or not
 */
const isAcrobatOnlyTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      packagedProducts.productList.length !== 1 ||
      packagedProducts.productList[0].sapCode !== 'APRO'
  );

/**
 * @description Method to check if the template contains Captivate
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains Captivate or not
 */
const isCaptivateTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.CPTV) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.RBHP) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.FM)
  );

/**
 * @description Method to check if the template contains Frame maker
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains framemaker or not
 */
const isFramemakerTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.FM) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.CPTV) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.RBHP)
  );

/**
 * @description Method to check if the template contains Photoshop elements
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains Photoshop elements or not
 */
const isPhotoshopElementsTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.PSE)
  );

/**
 * @description Method to check if the template contains Premier elements
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains Premier elements or not
 */
const isPremierElementsTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.PRE)
  );

/**
 * @description Method to check if the template contains Presenter
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains Presenter or not
 */
const isPresenterTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.CPTL) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.RBHP) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.FM)
  );

/**
 * @description Method to check if the template contains RHS
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains Robo Help Server or not
 */
const isRoboHelpServerTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some((packagedProducts) =>
    packagedProducts.productList.find((product) => product.sapCode !== PRODUCT_SAPCODES.RBSV)
  );

/**
 * @description Method to check if the template contains Robo help
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains robohelp or not
 */
const isRoboHelpTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.RBHP) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.CPTV) ||
      !!packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.FM)
  );

/**
 * @description Method to check if the template contains Presenter
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} if the template contains Presenter or not
 */
const isTechCommSuiteTemplate = (packagedProductsMap) =>
  !Object.values(packagedProductsMap).some(
    (packagedProducts) =>
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.RBHP) ||
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.FM) ||
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.APRO) ||
      !packagedProducts.productList.find((product) => product.sapCode === PRODUCT_SAPCODES.CPTV)
  );

/**
 * @description Retrieve id of string required in intl based on platform
 *
 * @param {String} platform platform
 * @returns {String} id of string required in intl
 */
const mapPlatformToId = (platform) => {
  switch (platform) {
    case CREATE_PACKAGE_CONSTANTS.MAC_PLATFORM:
      return 'mac';
    case CREATE_PACKAGE_CONSTANTS.MAC_ARM_PLATFORM:
      return 'macArm';
    case CREATE_PACKAGE_CONSTANTS.MACU_PLATFORM:
      return 'macUniversal';
    case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM:
      return 'win';
    case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM + CREATE_PACKAGE_CONSTANTS.THIRTY_TWO_BIT:
      return 'win32';
    case CREATE_PACKAGE_CONSTANTS.WIN_PLATFORM + CREATE_PACKAGE_CONSTANTS.SIXTY_FOUR_BIT:
      return 'win64';
    case CREATE_PACKAGE_CONSTANTS.WIN_ARM_PLATFORM:
      return 'winArm';
    default:
      return '';
  }
};

/**
 * @description Method to check if CCD should not be added
 *
 * @param {Object} packagedProductsMap packagedProductsMap
 * @returns {Boolean} True if CCD should not be added to the template
 */
const shouldNotAddCCD = (packagedProductsMap) =>
  isPresenterTemplate(packagedProductsMap) ||
  isTechCommSuiteTemplate(packagedProductsMap) ||
  isRoboHelpTemplate(packagedProductsMap) ||
  isFramemakerTemplate(packagedProductsMap) ||
  isRoboHelpServerTemplate(packagedProductsMap) ||
  isCaptivateTemplate(packagedProductsMap) ||
  isPhotoshopElementsTemplate(packagedProductsMap) ||
  isPremierElementsTemplate(packagedProductsMap);

/**
 * @description Method to trigger analytics for download of mac native packages
 * @param {Object} packageInfo Information of native package
 */
const sendAnalyticsForNativePackage = (packageInfo) => {
  const {
    error,
    isSingleApp,
    name,
    platform,
    nativePackageId,
    nativePackageSHA256,
    nativePackageSize,
    nativeProductsList,
  } = packageInfo;

  PackagesIngestAnalyticsService.triggerAnalytics({
    type: isSingleApp ? 'downloadSingleAppWorkflow' : 'downloadNativePackageWorkflow',
    value: {
      customerType: getCustomerType(),
      error,
      packageId: nativePackageId,
      packageName: name,
      packageType: PACKAGE_TYPE_CONSTANTS.MANAGED,
      platform,
      productsCount: nativeProductsList.length,
      selectedAdobeProductIds: nativeProductsList,
      SHA256: nativePackageSHA256,
      Size: nativePackageSize,
    },
  });
};

export {
  isAcrobatOnlyTemplate,
  isCaptivateTemplate,
  isFramemakerTemplate,
  isPhotoshopElementsTemplate,
  isPremierElementsTemplate,
  isPresenterTemplate,
  isRoboHelpServerTemplate,
  isRoboHelpTemplate,
  isTechCommSuiteTemplate,
  mapPlatformToId,
  shouldNotAddCCD,
  sendAnalyticsForNativePackage,
};
