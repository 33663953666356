import {dispatchUiEventAnalytics, feature} from '@admin-tribe/acsc';

import AutoAssignmentRulesModalDialog from 'common/components/sophia/sophia-modal/auto-assignment-rules-modal/AutoAssignmentRulesModalDialog';
import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import rootStore from 'core/RootStore';

const MODAL_DIALOG_MAP = {
  autoAssignmentRules: AutoAssignmentRulesModalDialog,
};

const mapModalDialogComponent = (dialogName) => MODAL_DIALOG_MAP[dialogName];
/**
 * Map ODIN content coming from Sophia to modal fields
 */
const mapContent = (sophiaContent) => {
  const nestedData = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  return {
    analyticsId: nestedData.analyticsId,
    body: nestedData.body.plaintext,
    body2: nestedData.body2?.plaintext,
    bodyImageRatio: nestedData.bodyImageRatio,
    bodyLearnMoreUrl: nestedData.bodyLearnMoreUrl,
    buttons: nestedData.buttons || [],
    heading: nestedData.heading,
    imageUrl: nestedData.imageUrl,
    modalSize: nestedData.modalSize,
    slotId: nestedData.slotId,
    slotOptions: nestedData.slotOptions,
  };
};

/**
 * Map ODIN Helpful Topics content coming from Sophia to modal fields
 */
const mapHelpfulTopicsContent = (sophiaContent) => {
  const topics = [];
  const helpfulTopics = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item.helpfulTopics;
  helpfulTopics.forEach((topic) => {
    topics.push({
      ctaLabel: topic.ctaLabel,
      ctaUrl: topic.ctaUrl,
      learnMoreText: topic.learnMoreText,
      learnMoreUrl: topic.learnMoreUrl,
      parent: topic.parent,
      text: topic.text,
      title: topic.title,
    });
  });

  return topics;
};

/**
 * Map ODIN content of promo section and banner coming from Sophia to modal fields
 */
const mapPromoContent = (sophiaContent) => {
  const content = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  return {
    analyticsId: content.analyticsId,
    backgroundColor: content.backgroundColor,
    backgroundImage: content.backgroundImage,
    badgeIcon: content.badgeIcon,
    badgeType: content.badgeColor,
    description: content.description?.plaintext,
    disclosure: content.disclosure?.plaintext,
    discountedPrice: content.discountedPrice,
    discountPriceColor: content.discountPriceColor,
    dismissible: content.dismissible,
    displayExcludingVAT: content.displayExcludingVAT,
    displayPriceStartingAt: content.displayPriceStartingAt,
    displayTerms: content.displayTerms,
    hoverOverText: content.hoverOverText?.plaintext,
    imageUrl: content.imageUrl,
    priceFrequency: content.priceFrequency,
    primaryCTAAction: content.primaryUrl,
    primaryCTALabel: content.primaryCTALabel,
    regularPrice: content.regularPrice,
    secondaryCTAAction: content.secondaryUrl,
    secondaryCTALabel: content.secondaryCTALabel,
    sectionTitle: content.sectionTitle,
    strikethroughColor: content.strikethroughColor,
    tag: content.badgeText,
    termsAndConditions: content.termsAndConditions?.plaintext,
    termsHeading: content.termsHeading,
    termsLabel: content.termsLabel,
    title: content.heading,
  };
};

/**
 * Map ODIN content of Hva banner of overview page card coming from Sophia to modal fields
 */
const mapOverviewHvaBannerContent = (sophiaContent) => {
  const content = sophiaContent.data[Object.keys(sophiaContent.data)[0]].item;
  /**
   * Find the product that is entitled to the org and also the product code.
   */
  const findProduct = (productCodesForCampaign, offerIds) => {
    if (!productCodesForCampaign || !offerIds) {
      return null;
    }

    const productList = rootStore.organizationStore.productList.items;
    // Here we are filtering the product code that is this org is entitled to and also the product code that is in the content
    // Find the product that is entitled to the org and also the product code that is from the given offers.
    const entitledProductsForCampaign = [];
    productCodesForCampaign.forEach((productCode) => {
      offerIds[productCode].forEach((offerId) => {
        entitledProductsForCampaign.push(
          productList.filter(
            (product) => product.code === productCode && offerId === product.offerId
          )
        );
      });
    });
    // Do not show the banner if the org is not entitled to any of the products.
    if (entitledProductsForCampaign.flat().length === 0) {
      return null;
    }

    // If there is more than one product code, pick the first one.
    // Confirmed by Bernardo and logged here: https://jira.corp.adobe.com/browse/ONESIE-43885?focusedId=45151931&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-45151931
    return entitledProductsForCampaign.flat()[0];
  };

  const displayCampaign = () => {
    if (content.modalDialogName === 'autoAssignmentRules') {
      return (
        findProduct(content.modalDialog.productCode?.split(','), content.modalDialog.offerIds) &&
        feature.isEnabled('temp_tno_overview_hva_banner_modal')
      );
    }
    return true;
  };

  if (displayCampaign()) {
    return {
      analyticsId: content.analyticsId,
      ctaLabel: content.primaryCtaLabel,
      description: content.description?.plaintext,
      dismissOnCTA: true,
      dismissOnCTATemp: true,
      header: content.heading,
      icon: {
        src: content.imageUrl,
      },
      // eslint-disable-next-line no-underscore-dangle -- Sophia system returned
      id: content._path,
      isDismissible: content.isDismissible,
      learnMoreHref: content.learnMoreUrl,
      ...(content.modalDialog
        ? {
            modalConfig: {
              closeProp: 'onClose',
              closeWithoutDismissProp: 'onCloseWithoutDismiss',
              Component: mapModalDialogComponent(content.modalDialogName),
              includeModalContainer: true,
              props: {
                callBeforeOpeningModal: () =>
                  dispatchUiEventAnalytics({
                    eventAction: 'click',
                    eventName: 'autoassignment:jitrule:tryitnow',
                  }),
                content: content.modalDialog,
                product: findProduct(
                  content.modalDialog.productCode.split(','),
                  content.modalDialog.offerIds
                ),
              },
            },
          }
        : {onCTA: getCtaClickEventCallback(content.primaryActionUrl)}),
      sectionTitle: content.sectionTitle,
    };
  }

  return null;
};

export {mapContent, mapHelpfulTopicsContent, mapPromoContent, mapOverviewHvaBannerContent};
