import {computed, makeObservable, observable} from 'mobx';

import AppConstants from 'common/services/AppConstants';

const IDP_TYPES = {
  AZURE: 'AAD',
  OKTA: 'OKTA',
  SAML: 'GS',
  SOIDC: 'SOIDC',
};

const UPDATE_STRATEGIES = {
  ALWAYS_UPDATE: 'ALWAYS_UPDATE',
  DO_NOT_UPDATE: 'DO_NOT_UPDATE',
  UPDATE_WHEN_NOT_EMPTY: 'UPDATE_WHEN_NOT_EMPTY',
};

const SOIDC_NAMES = {
  BLACKBOARD: 'Blackboard',
  CANVAS: 'Canvas',
  CLASSLINK: 'Classlink',
  CLEVER: 'Clever',
  GOOGLE: 'google',
  SCHOOLOGY: 'Schoology',
  WONDE: 'Wonde',
};

const IDP_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  PENDING: 'PENDING',
};

const IDENTITY_PROVIDERS = {
  ADFS: 'ADFS',
  CLASSLINK: 'CLASSLINK',
  GOOGLE: 'GOOGLE',
  OKTA: 'OKTA',
  ONELOGIN: 'ONELOGIN',
  SHIBBOLETH: 'SHIBBOLETH',
  STSWINDOWS: 'STSWINDOWS',
};

// The OKTA IdPs should become invisible after *DAYS_VISIBLE_FOR*
// days since they were last the default IdP. Measured in [ms].
const DAYS_VISIBLE_FOR = 7;
const MS_IN_DAY = 24 * 60 * 60 * 1000;

class IdpEntity {
  defaultIdp;
  idpData = {};
  status;

  constructor(data) {
    Object.assign(
      this,
      {
        jitConfig: {
          accountCreation: false,
          syncStrategy: UPDATE_STRATEGIES.DO_NOT_UPDATE,
        },
        spAlias: null,
      },
      data,
      // Replace entityId property with the spEntityId value
      {entityId: data?.spEntityId || null}
    );

    makeObservable(this, {
      acsUrl: computed,
      canBeConfigured: computed,
      canBeDefault: computed,
      defaultIdp: observable,
      isActive: computed,
      isAzure: computed,
      isDefault: computed,
      isDisabled: computed,
      isGoogleSaml: computed,
      isGoogleSoidc: computed,
      isOidc: computed,
      isOkta: computed,
      isRemovable: computed,
      isSaml: computed,
      isSoidc: computed,
      isTestable: computed,
      isUsageDeprecated: computed,
      jitStatus: computed,
      status: observable,
      updateStrategy: computed,
    });
  }

  get acsUrl() {
    // return undefined if the IdP is not saml to not confuse someone that acsUrl is available
    if (!this.isSaml) {
      return;
    }

    // eslint-disable-next-line consistent-return -- implicit return is undefined
    return `${AppConstants.configuration.services.imsFederated.acsUrl}/${this.spAlias}`;
  }

  get canBeConfigured() {
    return this.isSaml && !this.isActive;
  }

  get canBeDefault() {
    if (this.isSoidc) {
      return this.isSupportsFrontChannelAuth && this.isActive && !this.isDefault;
    }

    return this.isActive && !this.isDefault;
  }

  get isActive() {
    return this.status === IDP_STATUS.ACTIVE;
  }

  get isAzure() {
    return this.federationType === IDP_TYPES.AZURE;
  }

  get isDefault() {
    return this.defaultIdp === this.id;
  }

  get isDisabled() {
    return this.status === IDP_STATUS.DISABLED;
  }

  get isGoogleSaml() {
    return this.isSaml && this.sniffedProvider === IDENTITY_PROVIDERS.GOOGLE;
  }

  get isGoogleSoidc() {
    return this.providerName === SOIDC_NAMES.GOOGLE;
  }

  get isOidc() {
    return this.federationType === IDP_TYPES.SOIDC || this.federationType === IDP_TYPES.AZURE;
  }

  get isOkta() {
    return this.federationType === IDP_TYPES.OKTA;
  }

  get isRemovable() {
    return !this.isDefault && !this.isOkta;
  }

  get isSaml() {
    return this.federationType === IDP_TYPES.SAML;
  }

  get isSoidc() {
    return this.federationType === IDP_TYPES.SOIDC;
  }

  get isTestable() {
    if (this.isSoidc) {
      return this.isSupportsFrontChannelAuth && this.isActive;
    }

    return !!this.testUrl && this.isActive;
  }

  get isUsageDeprecated() {
    if (!this.isOkta || this.isDefault) {
      return false;
    }

    // For how long after becoming non-default should card remain visible.
    // If we don't have a last default time we consider it deprecated.
    return (
      !this.lastDefaultTime || Date.now() - this.lastDefaultTime >= DAYS_VISIBLE_FOR * MS_IN_DAY
    );
  }

  get jitStatus() {
    return this.jitConfig.accountCreation;
  }

  get updateStrategy() {
    return this.jitConfig.syncStrategy;
  }
}

export {IDP_TYPES, IDP_STATUS, IDENTITY_PROVIDERS, UPDATE_STRATEGIES, SOIDC_NAMES};
export default IdpEntity;
