import {AUTHENTICATION_ERROR, AuthenticatedUser, authentication, log} from '@admin-tribe/acsc';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';

import {useConfiguration} from '../configuration/ConfigurationProvider';
import {useLocale} from '../locale/LocaleProvider';

const AuthenticationContext = createContext();

/**
 * Provides Authentication data to its children components.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @returns {React.ReactNode} The wrapped children components.
 */
const AuthenticationProvider = ({children}) => {
  const {configuration, configurationLoaded} = useConfiguration();
  const {localeObject, localeLoaded} = useLocale();
  const [profile, setProfile] = useState(null);
  const [roles, setRoles] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [authenticationLoaded, setAuthenticationLoaded] = useState(false);
  const handleError = useErrorHandler();
  const ERR_ROLES_NOT_FOUND = 'Roles not found';

  const checkRoles = useCallback((config, userRoles) => {
    const {includeRoles, requiredRoles} = config;

    let filteredUserRoles = userRoles;
    if (!isEmpty(includeRoles)) {
      filteredUserRoles = intersection(userRoles, includeRoles);
    }

    if (!isEmpty(requiredRoles) && intersection(filteredUserRoles, requiredRoles).length === 0) {
      return false;
    }
    return true;
  }, []);

  const updateProfileAndRoles = useCallback((userProfile, authenticatedUser) => {
    setProfile(userProfile);
    setRoles(authenticatedUser.getRoles());
  }, []);

  const initializeAuthentication = useCallback(
    async (imsConfig) => {
      authentication.setLocale(localeObject.activeLanguage);
      const userProfile = await authentication.initialize(imsConfig);
      const authenticatedUser = new AuthenticatedUser(userProfile);
      return {authenticatedUser, userProfile};
    },
    [localeObject]
  );

  // Fetch data from the Authentication file
  useEffect(() => {
    const fetchData = async () => {
      if (!(configurationLoaded && localeLoaded)) return;

      try {
        const imsConfig = configuration.services.ims;
        const {userProfile, authenticatedUser} = await initializeAuthentication(imsConfig);
        const rolesConfig = {
          includeRoles: configuration.includeRoles,
          requiredRoles: configuration?.ready?.authentication?.requiredRoles,
        };
        if (!isEmpty(rolesConfig.includeRoles)) {
          authenticatedUser.getRoles().filterTo(rolesConfig.includeRoles);
        }

        if (!checkRoles(rolesConfig, authenticatedUser.getRoles())) {
          throw new Error(ERR_ROLES_NOT_FOUND);
        }

        updateProfileAndRoles(userProfile, authenticatedUser);
        setAuthenticationLoaded(true);
      } catch (error) {
        log.log('Error authenticating:', error);

        if (error.message === AUTHENTICATION_ERROR.USER_SIGNED_OUT) {
          authentication.signIn();
          return;
        }

        setHasError(true);
        handleError(error);
      }
    };

    fetchData();
  }, [
    configurationLoaded,
    configuration,
    localeLoaded,
    localeObject,
    handleError,
    checkRoles,
    initializeAuthentication,
    updateProfileAndRoles,
  ]);

  const contextValue = useMemo(
    () => ({authenticationLoaded, profile, roles}),
    [authenticationLoaded, profile, roles]
  );

  return (
    !hasError &&
    configurationLoaded &&
    localeLoaded && (
      <AuthenticationContext.Provider value={contextValue}>
        {children}
      </AuthenticationContext.Provider>
    )
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuthentication = () => useContext(AuthenticationContext);

export {AuthenticationProvider, useAuthentication};
