import {dispatchUiEventAnalytics} from '@admin-tribe/acsc';
import {
  GoUrl,
  OverlayWait,
  Page,
  PageContent,
  PageDescription,
  PageHeader,
  PageNav,
  showError,
} from '@admin-tribe/acsc-ui';
import {Flex, Heading, Radio, RadioGroup} from '@adobe/react-spectrum';
import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import rootStore from 'core/RootStore';

import useAppAddOnsPolicyData from './useAppAddOnsPolicyData';

/**
 * Defines the product app add-ons page under the Products tab.
 */
const ProductAppAddOnsPage = () => {
  const intl = useIntl();
  const orgId = rootStore.organizationStore.activeOrgId;

  const [selected, setSelected] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const {policyData} = useAppAddOnsPolicyData(orgId);

  // use policy data to determine state of selection
  useEffect(() => {
    const addOnsAllowed = policyData?.items?.some(
      (policy) => policy.name === 'webBasedProductAddOnsEnabled' && policy.value === true
    );
    const addOnsSelection = addOnsAllowed ? 'allow' : 'block';
    setSelected(addOnsSelection);
  }, [policyData]);

  const onSelectionChange = async (event) => {
    let isSuccess = false;
    setIsUpdating(true);
    try {
      await policyData.updatePolicy({
        orgId,
        policy: 'webBasedProductAddOnsEnabled',
        policyValue: event === 'allow',
      });
      isSuccess = true;
    } catch {
      showError(intl.formatMessage({id: 'products.appAddOns.content.error'}));
    } finally {
      setIsUpdating(false);
    }

    if (isSuccess) {
      setSelected(event);

      dispatchUiEventAnalytics({
        eventAction: 'click',
        eventName: event === 'allow' ? 'allowAppAddOns' : 'blockAppAddOns',
      });
    }
  };

  return (
    <Page data-testid="app-add-ons-page" isLoading={false}>
      <PageHeader
        title={intl.formatMessage({
          id: 'products.appAddOns.title',
        })}
      />
      <PageDescription>
        <FormattedMessage
          id="products.appAddOns.description"
          values={{
            goUrl: (linkText) => <GoUrl name="addons-launch">{linkText}</GoUrl>,
          }}
        />
      </PageDescription>
      <PageNav />
      <PageContent>
        <Heading level={2} marginTop="size-10" UNSAFE_style={{fontWeight: 'bold'}}>
          {intl.formatMessage({
            id: 'products.appAddOns.content.title',
          })}
        </Heading>
        <Flex direction="column" rowGap="size-100">
          <FormattedMessage
            id="products.appAddOns.content.description"
            values={{
              goUrl: (linkText) => <GoUrl name="addons-learn">{linkText}</GoUrl>,
            }}
          />
          <OverlayWait isLoading={!policyData || isUpdating === true} showContent>
            {policyData && (
              <RadioGroup
                aria-label={intl.formatMessage({
                  id: 'products.appAddOns.content.label',
                })}
                onChange={onSelectionChange}
                value={selected}
              >
                <Radio value="allow">
                  {intl.formatMessage({
                    id: 'products.appAddOns.content.allowAddOns',
                  })}
                </Radio>
                <Radio value="block">
                  {intl.formatMessage({
                    id: 'products.appAddOns.content.blockAddOns',
                  })}
                </Radio>
              </RadioGroup>
            )}
          </OverlayWait>
        </Flex>
      </PageContent>
    </Page>
  );
};

export default ProductAppAddOnsPage;
